<!-- android端用户协议 -->

<template>
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width,initial-scale=1" />
    <meta http-equiv="X-UA-Compatible" content="ie=edge" />
    <title>用户协议</title>
  </head>

  <div id="write" class="first-line-indent">
    <p>更新日期：2024年10月08日<br />发布日期：2024年10月08日</p>

    <p>
      欢迎您（即「用户」）使用我们提供的服务，本声明的发布者为二十四小时买卖网（大连）科技有限公司
    </p>
    <p>
      请您仔细阅读、充分理解以下条款，您一旦登录服务，即视为您已经完全同意本协议各项内容，包括我们对使用协议所做的任何修改。
      如果您对本协议的任何条款表示异议，您可以选择终止登录（或停止使用服务）。
    </p>
    <p>
      <strong>1.接受条款</strong>
    </p>
    <p>
      1.1 我们提供的服务根据本协议的条款为用户提供服务；
      用户一旦登录成功，无论是进入我们提供的服务，还是在我们提供的服务上发布任何内容，均意味着用户完全接受本协议项下的全部条款。
    </p>
    <p>
      1.2 用户使用我们提供的单项服务时，应遵守与该服务相关的指引和规则。
      所有的指引和规则，均构成本使用协议的一部分。
    </p>
    <p>
      1.3 用户直接或通过各类方式（如 SDK和站外 API
      引用等）间接使用我们提供的服务和数据的行为，都将被视作已无条件接受本协议全部内容。
    </p>
    <p>
      1.4 若用户对本协议的任何条款存在异议，请停止使用我们所提供的全部服务。
    </p>
    <p>
      1.5 用户应遵守本协议各项条款，合法合理使用我们提供的服务；
      否则，我们有权依据本协议终止为用户提供服务。
    </p>
    <p>1.6 我们保留在任何时候收回用户所使用账号的权利。</p>
    <p>
      <strong>2.使用规则</strong>
    </p>
    <p>
      2.1
      用户须对在我们提供的服务的登录信息的真实性、合法性、有效性承担全部责任，用户不得冒充他人；
      不得利用他人的名义发布任何信息； 不得恶意使用帐号导致其他用户误认。
    </p>
    <p>
      2.2
      我们提供的服务是一个信息分享、传播及获取的平台，用户通过我们提供的服务发表的信息为公开的信息，其他第三方均可以通过我们提供的服务获取用户发表的信息，用户对任何信息的发表即认可该信息为公开的信息，并单独对此行为承担法律责任；
      任何用户不愿被其他第三人获知的信息都不应该在我们提供的服务上进行发表，否则均视为用户自愿将其公开。
    </p>
    <p>
      2.3
      用户承诺不得以任何方式利用我们提供的服务直接或间接从事违反中国法律以及社会公德的行为，我们提供的服务有权对违反上述承诺的内容予以删除。
    </p>
    <p>
      2.4
      用户不得利用我们提供的服务制作、上传、复制、发布、传播或者转载如下内容：
    </p>
    <p>2.4.1反对宪法所确定的基本原则的；</p>
    <p>2.4.2危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
    <p>2.4.3损害国家荣誉和利益的；</p>
    <p>2.4.3煽动民族仇恨、民族歧视，破坏民族团结的；</p>
    <p>2.4.4侮辱、滥用英烈形象，否定英烈事迹，美化粉饰侵略战争行为的；</p>
    <p>2.4.5破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
    <p>2.4.6散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
    <p>2.4.7散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
    <p>2.4.8侮辱或者诽谤他人，侵害他人合法权益的；</p>
    <p>
      2.4.9含有虚假、诈骗、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其他道德上令人反感的内容；
    </p>
    <p>
      2.4.9含有中国法律、法规、（部门）规章以及任何具有法律效力之规范所限制或禁止的其他内容的；
    </p>
    <p>2.4.10含有我们提供的服务认为不适合在我们提供的服务展示的内容。</p>
    <p>
      <strong>3.知识产权</strong>
    </p>
    <p>
      我们提供的服务是一个信息获取、分享及传播的平台，我们尊重和鼓励用户创作的内容，认识到保护知识产权对我们提供的服务生存与发展的重要性，承诺将保护知识产权作为我们提供的服务运营的基本原则之一。
    </p>
    <p>
      3.1
      在我们提供的服务上传或发表的内容，用户应保证其为著作权人或已取得合法授权，并且该内容不会侵犯任何第三方的合法权益。
      如果第三方提出关于著作权的异议，我们提供的服务有权根据实际情况删除相关的内容，且有权追究用户的法律责任。
      给我们提供的服务或任何第三方造成损失的，用户应负责全额赔偿。
    </p>
    <p>
      3.2
      如果任何第三方侵犯了我们提供的服务用户相关的权利，用户同意授权我们提供的服务或其指定的代理人代表我们提供的服务自身或用户对该第三方提出警告、投诉、诉讼或谈判和解，并且用户同意在我们提供的服务认为必要的情况下参与共同维权。
    </p>
    <p>
      3.3
      我们提供的服务有权但无义务对用户发布的内容进行审核，有权根据相关证据结合《侵权责任法》、《信息网络传播权保护条例》等法律法规及我们提供的服务指导原则对侵权信息进行处理。
    </p>
    <p>
      <strong>4.个人隐私</strong>
    </p>
    <p>
      4.1
      除法律或有法律赋予权限的政府部门要求或事先得到用户明确授权等原因外，我们提供的服务保证不对外公开或向第三方透露用户个人隐私信息，或用户在使用服务时存储的非公开内容。
      同时，为了运营和改善我们提供的技术与服务，我们提供的服务将可能会自行收集使用或向第三方提供用户的非个人隐私信息，这将有助于我们向用户提供更好的用户体验和服务质量。
    </p>
    <p>
      <strong>5.免责声明</strong>
    </p>
    <p>
      5.1 我们提供的服务不能对用户发布的内容或评论的安全性、正确性进行保证。
    </p>
    <p>
      5.2
      用户在我们提供的服务发表的内容仅表明其个人的立场和观点，并不代表我们提供的服务的立场或观点。作为内容的发表者，需自行对所发表内容负责，因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律及连带责任。
      我们提供的服务不承担任何法律及连带责任，若我们因此承担责任的，发表者应向我们赔偿造成的一切损失。
    </p>
    <p>
      5.3
      我们提供的服务不保证网络服务一定能满足用户的要求，也不保证网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作保证，用户对此知情并认可。
    </p>
    <p>
      5.4
      对于因不可抗力或我们提供的服务不能控制的原因造成的网络服务中断或其它缺陷，我们提供的服务不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。
    </p>
    <p>
      <strong>6.协议修改</strong>
    </p>
    <p>
      6.1
      根据互联网的发展和有关法律、法规及规范性文件的变化，或者因业务发展需要，我们提供的服务有权对本协议的条款作出修改或变更，一旦本协议的内容发生变动，我们提供的服务将会在网站上公布修改之后的协议内容，该公布行为视为我们提供的服务已经通知用户修改内容。我们提供的服务也可采用站内消息的传送方式，提示用户使用协议条款的修改、服务变更、或其它重要事项。
    </p>
    <p>
      6.2
      如果不同意我们提供的服务对本协议相关条款所做的修改，用户有权并应当停止使用我们提供的服务。
      如果用户继续使用我们提供的服务，则视为用户接受我们提供的服务对本协议相关条款所做的修改。
    </p>
    <p>
      <strong>7.与我们联系</strong>
    </p>
    <p>
      如您对本政策或其他相关事宜有疑问，请根据我们提供的指引，填写相关资料，将您的问题发送至<a
        href=""
        >developer@qqspmmw.com</a
      >
      ，我们将尽快审核所涉问题，并在验证您的用户身份后的三十天内予以回复。
    </p>
    <p>
      公司名称：二十四小时买卖网（大连）科技有限公司<br />
      统一社会信用代码：91210204MA11807P2Q<br />
      地址：中国辽宁省大连市沙河口区长兴街139号13层3号<br />
      联系电话: 0411-84641106<br />
    </p>
  </div>
</template>

<script>
export default {
  name: "UserAgreement",
  data() {
    return {};
  },
};
</script>

<style scoped>
html {
  overflow-x: initial !important;
}

:root {
  --bg-color: #ffffff;
  --text-color: #333333;
  --select-text-bg-color: #b5d6fc;
  --select-text-font-color: auto;
  --monospace: "Lucida Console", Consolas, "Courier", monospace;
  --title-bar-height: 20px;
}

.mac-os-11 {
  --title-bar-height: 28px;
}

html {
  font-size: 14px;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}

body {
  color: #34495e !important;
}

body {
  margin: 0;
  padding: 0;
  height: auto;
  inset: 0;
  font-size: 1rem;
  overflow-x: hidden;
  background-image: inherit;
  background-size: inherit;
  background-attachment: inherit;
  background-origin: inherit;
  background-clip: inherit;
  background-color: inherit;
  tab-size: 4;
  background-position: inherit;
  background-repeat: inherit;
}

iframe {
  margin: auto;
}

a.url {
  word-break: break-all;
}

a:active,
a:hover {
  outline: 0;
}

.in-text-selection,
::selection {
  text-shadow: none;
  background: var(--select-text-bg-color);
  color: var(--select-text-font-color);
}

#write {
  margin: 0 auto;
  height: auto;
  width: inherit;
  word-break: normal;
  word-wrap: break-word;
  position: relative;
  white-space: normal;
  overflow-x: visible;
  padding-top: 36px;
}

#write.first-line-indent li p,
#write.first-line-indent p * {
  text-indent: 0;
}

#write.first-line-indent li {
  margin-left: 2em;
}

.for-image #write {
  padding-left: 8px;
  padding-right: 8px;
}

body.typora-export {
  padding-left: 30px;
  padding-right: 30px;
}

.typora-export .footnote-line,
.typora-export li,
.typora-export p {
  white-space: pre-wrap;
}

.typora-export .task-list-item input {
  pointer-events: none;
}

@media screen and (max-width: 500px) {
  body.typora-export {
    padding-left: 0;
    padding-right: 0;
  }

  #write {
    padding-left: 20px;
    padding-right: 20px;
  }

  .CodeMirror-sizer {
    margin-left: 0 !important;
  }

  .CodeMirror-gutters {
    display: none !important;
  }
}

#write li > figure:last-child {
  margin-bottom: 0.5rem;
}

#write ol,
#write ul {
  position: relative;
}

img {
  max-width: 100%;
  vertical-align: middle;
  image-orientation: from-image;
}

button,
input,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-variant-caps: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  line-height: inherit;
}

input[type="checkbox"],
input[type="radio"] {
  line-height: normal;
  padding: 0;
}

*,
::after,
::before {
  box-sizing: border-box;
}

#write h1,
#write h2,
#write h3,
#write h4,
#write h5,
#write h6,
#write p,
#write pre {
  width: inherit;
}

#write h1,
#write h2,
#write h3,
#write h4,
#write h5,
#write h6,
#write p {
  position: relative;
}

p {
  line-height: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  break-after: avoid-page;
  break-inside: avoid;
  orphans: 4;
}

p {
  orphans: 4;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.8rem;
}

h3 {
  font-size: 1.6rem;
}

h4 {
  font-size: 1.4rem;
}

h5 {
  font-size: 1.2rem;
}

h6 {
  font-size: 1rem;
}

.md-math-block,
.md-rawblock,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.hidden {
  display: none;
}

.md-blockmeta {
  color: #ccc;
  font-weight: 700;
  font-style: italic;
}

a {
  cursor: pointer;
}

sup.md-footnote {
  padding: 2px 4px;
  background-color: rgba(238, 238, 238, 0.7);
  color: #555;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  cursor: pointer;
}

sup.md-footnote a,
sup.md-footnote a:hover {
  color: inherit;
  text-transform: inherit;
  text-decoration: inherit;
}

#write input[type="checkbox"] {
  cursor: pointer;
  width: inherit;
  height: inherit;
}

figure {
  overflow-x: auto;
  margin: 1.2em 0;
  max-width: calc(100% + 16px);
  padding: 0;
}

figure > table {
  margin: 0;
}

tr {
  break-inside: avoid;
  break-after: auto;
}

thead {
  display: table-header-group;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  overflow: auto;
  break-inside: auto;
  text-align: left;
}

table.md-table td {
  min-width: 32px;
}

.CodeMirror-gutters {
  border-right-width: 0;
  background-color: inherit;
}

.CodeMirror {
  text-align: left;
}

.CodeMirror-placeholder {
  opacity: 0.3;
}

.CodeMirror pre {
  padding: 0 4px;
}

.CodeMirror-lines {
  padding: 0;
}

div.hr:focus {
  cursor: none;
}

#write pre {
  white-space: pre-wrap;
}

#write.fences-no-line-wrapping pre {
  white-space: pre;
}

#write pre.ty-contain-cm {
  white-space: normal;
}

.CodeMirror-gutters {
  margin-right: 4px;
}

.md-fences {
  font-size: 0.9rem;
  display: block;
  break-inside: avoid;
  text-align: left;
  overflow: visible;
  white-space: pre;
  background-image: inherit;
  background-size: inherit;
  background-attachment: inherit;
  background-origin: inherit;
  background-clip: inherit;
  background-color: inherit;
  position: relative !important;
  background-position: inherit;
  background-repeat: inherit;
}

.md-fences-adv-panel {
  width: 100%;
  margin-top: 10px;
  text-align: center;
  padding-top: 0;
  padding-bottom: 8px;
  overflow-x: auto;
}

#write .md-fences.mock-cm {
  white-space: pre-wrap;
}

.md-fences.md-fences-with-lineno {
  padding-left: 0;
}

#write.fences-no-line-wrapping .md-fences.mock-cm {
  white-space: pre;
  overflow-x: auto;
}

.md-fences.mock-cm.md-fences-with-lineno {
  padding-left: 8px;
}

.CodeMirror-line,
twitterwidget {
  break-inside: avoid;
}

.footnotes {
  opacity: 0.8;
  font-size: 0.9rem;
  margin-top: 1em;
  margin-bottom: 1em;
}

.footnotes + .footnotes {
  margin-top: 0;
}

.md-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: top;
  text-decoration: none;
  text-shadow: none;
  float: none;
  position: static;
  width: auto;
  height: auto;
  white-space: nowrap;
  cursor: inherit;
  line-height: normal;
  font-weight: 400;
  text-align: left;
  box-sizing: content-box;
  direction: ltr;
  background-position: 0 0;
}

li div {
  padding-top: 0;
}

blockquote {
  margin: 1rem 0;
}

li .mathjax-block,
li p {
  margin: 0.5rem 0;
}

li blockquote {
  margin: 1rem 0;
}

li {
  margin: 0;
  position: relative;
}

blockquote > :last-child {
  margin-bottom: 0;
}

blockquote > :first-child,
li > :first-child {
  margin-top: 0;
}

.footnotes-area {
  color: #888;
  margin-top: 0.714rem;
  padding-bottom: 0.143rem;
  white-space: normal;
}

#write .footnote-line {
  white-space: pre-wrap;
}

@media print {
  body,
  html {
    border: 1px solid transparent;
    height: 99%;
    break-after: avoid;
    break-before: avoid;
    font-variant-ligatures: no-common-ligatures;
  }

  #write {
    margin-top: 0;
    padding-top: 0;
    border-color: transparent !important;
  }

  .typora-export * {
    -webkit-print-color-adjust: exact;
  }

  .typora-export #write {
    break-after: avoid;
  }

  .typora-export #write::after {
    height: 0;
  }

  .is-mac table {
    break-inside: avoid;
  }

  .typora-export-show-outline .typora-export-sidebar {
    display: none;
  }
}

.footnote-line {
  margin-top: 0.714em;
  font-size: 0.7em;
}

a img,
img a {
  cursor: pointer;
}

pre.md-meta-block {
  font-size: 0.8rem;
  min-height: 0.8rem;
  white-space: pre-wrap;
  background-color: #ccc;
  display: block;
  overflow-x: hidden;
}

p > .md-image:only-child:not(.md-img-error) img,
p > img:only-child {
  display: block;
  margin: auto;
}

#write.first-line-indent p > .md-image:only-child:not(.md-img-error) img {
  left: -2em;
  position: relative;
}

p > .md-image:only-child {
  display: inline-block;
  width: 100%;
}

#write .MathJax_Display {
  margin: 0.8em 0 0;
}

.md-math-block {
  width: 100%;
}

.md-math-block:not(:empty)::after {
  display: none;
}

.MathJax_ref {
  fill: currentcolor;
}

[contenteditable="false"]:active,
[contenteditable="false"]:focus,
[contenteditable="true"]:active,
[contenteditable="true"]:focus {
  outline: 0;
  box-shadow: none;
}

.md-task-list-item {
  position: relative;
  list-style-type: none;
}

.task-list-item.md-task-list-item {
  padding-left: 0;
}

.md-task-list-item > input {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -1.2em;
  margin-top: calc(1em - 10px);
  border: none;
}

.math {
  font-size: 1rem;
}

.md-toc {
  min-height: 3.58rem;
  position: relative;
  font-size: 0.9rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.md-toc-content {
  position: relative;
  margin-left: 0;
}

.md-toc-content::after,
.md-toc::after {
  display: none;
}

.md-toc-item {
  display: block;
  color: #4183c4;
}

.md-toc-item a {
  text-decoration: none;
}

.md-toc-inner:hover {
  text-decoration: underline;
}

.md-toc-inner {
  display: inline-block;
  cursor: pointer;
}

.md-toc-h1 .md-toc-inner {
  margin-left: 0;
  font-weight: 700;
}

.md-toc-h2 .md-toc-inner {
  margin-left: 2em;
}

.md-toc-h3 .md-toc-inner {
  margin-left: 4em;
}

.md-toc-h4 .md-toc-inner {
  margin-left: 6em;
}

.md-toc-h5 .md-toc-inner {
  margin-left: 8em;
}

.md-toc-h6 .md-toc-inner {
  margin-left: 10em;
}

@media screen and (max-width: 48em) {
  .md-toc-h3 .md-toc-inner {
    margin-left: 3.5em;
  }

  .md-toc-h4 .md-toc-inner {
    margin-left: 5em;
  }

  .md-toc-h5 .md-toc-inner {
    margin-left: 6.5em;
  }

  .md-toc-h6 .md-toc-inner {
    margin-left: 8em;
  }
}

a.md-toc-inner {
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.footnote-line a:not(.reversefootnote) {
  color: inherit;
}

.md-attr {
  display: none;
}

.md-fn-count::after {
  content: ".";
}

code,
pre,
samp,
tt {
  font-family: var(--monospace);
}

kbd {
  margin: 0 0.1em;
  padding: 0.1em 0.6em;
  font-size: 0.8em;
  color: #242729;
  background-color: #fff;
  border: 1px solid #adb3b9;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  box-shadow: rgba(12, 13, 14, 0.2) 0 1px 0, #fff 0 0 0 2px inset;
  white-space: nowrap;
  vertical-align: middle;
}

.md-comment {
  color: #a27f03;
  opacity: 0.6;
  font-family: var(--monospace);
}

code {
  text-align: left;
}

a.md-print-anchor {
  white-space: pre !important;
  border: none !important;
  display: inline-block !important;
  position: absolute !important;
  width: 1px !important;
  right: 0 !important;
  outline: 0 !important;
  text-shadow: initial !important;
  background-position: 0 0 !important;
}

.os-windows.monocolor-emoji .md-emoji {
  font-family: "Segoe UI Symbol", sans-serif;
}

.md-diagram-panel > svg {
  max-width: 100%;
}

[lang="flow"] svg,
[lang="mermaid"] svg {
  max-width: 100%;
  height: auto;
}

[lang="mermaid"] .node text {
  font-size: 1rem;
}

table tr th {
  border-bottom-width: 0;
}

video {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

iframe {
  max-width: 100%;
  width: 100%;
  border: none;
}

.highlight td,
.highlight tr {
  border: 0;
}

mark {
  background-color: #ff0;
  color: #000;
}

.md-html-inline .md-plain,
.md-html-inline strong,
mark .md-inline-math,
mark strong {
  color: inherit;
}

.md-expand mark .md-meta {
  opacity: 0.3 !important;
}

mark .md-meta {
  color: #000;
}

@media print {
  .typora-export h1,
  .typora-export h2,
  .typora-export h3,
  .typora-export h4,
  .typora-export h5,
  .typora-export h6 {
    break-inside: avoid;
  }
}

.md-diagram-panel .messageText {
  stroke: none !important;
}

.md-diagram-panel .start-state {
  fill: var(--node-fill);
}

.md-diagram-panel .edgeLabel rect {
  opacity: 1 !important;
}

.md-fences.md-fences-math {
  font-size: 1em;
}

.md-fences-advanced:not(.md-focus) {
  padding: 0;
  white-space: nowrap;
  border: 0;
}

.md-fences-advanced:not(.md-focus) {
  background-image: inherit;
  background-size: inherit;
  background-attachment: inherit;
  background-origin: inherit;
  background-clip: inherit;
  background-color: inherit;
  background-position: inherit;
  background-repeat: inherit;
}

.typora-export-show-outline .typora-export-content {
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: row;
}

.typora-export-sidebar {
  width: 300px;
  font-size: 0.8rem;
  margin-top: 80px;
  margin-right: 18px;
}

.typora-export-show-outline #write {
  --webkit-flex: 2;
  flex: 2 1 0%;
}

.typora-export-sidebar .outline-content {
  position: fixed;
  top: 0;
  max-height: 100%;
  overflow: hidden auto;
  padding-bottom: 30px;
  padding-top: 60px;
  width: 300px;
}

@media screen and (max-width: 1024px) {
  .typora-export-sidebar,
  .typora-export-sidebar .outline-content {
    width: 240px;
  }
}

@media screen and (max-width: 800px) {
  .typora-export-sidebar {
    display: none;
  }
}

.outline-content li,
.outline-content ul {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  list-style: none;
}

.outline-content ul {
  margin-top: 0;
  margin-bottom: 0;
}

.outline-content strong {
  font-weight: 400;
}

.outline-expander {
  width: 1rem;
  height: 1.428571429rem;
  position: relative;
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
  padding-left: 4px;
}

.outline-expander::before {
  content: "";
  position: relative;
  font-family: Ionicons;
  display: inline-block;
  font-size: 8px;
  vertical-align: middle;
}

.outline-item {
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;
}

.outline-expander:hover::before {
  content: "";
}

.outline-h1 > .outline-item {
  padding-left: 0;
}

.outline-h2 > .outline-item {
  padding-left: 1em;
}

.outline-h3 > .outline-item {
  padding-left: 2em;
}

.outline-h4 > .outline-item {
  padding-left: 3em;
}

.outline-h5 > .outline-item {
  padding-left: 4em;
}

.outline-h6 > .outline-item {
  padding-left: 5em;
}

.outline-label {
  cursor: pointer;
  display: table-cell;
  vertical-align: middle;
  text-decoration: none;
  color: inherit;
}

.outline-label:hover {
  text-decoration: underline;
}

.outline-item:hover {
  border-color: #f5f5f5;
  background-color: var(--item-hover-bg-color);
}

.outline-item:hover {
  margin-left: -28px;
  margin-right: -28px;
  border-left-width: 28px;
  border-left-style: solid;
  border-left-color: transparent;
  border-right-width: 28px;
  border-right-style: solid;
  border-right-color: transparent;
}

.outline-item-single .outline-expander::before,
.outline-item-single .outline-expander:hover::before {
  display: none;
}

.outline-item-open > .outline-item > .outline-expander::before {
  content: "";
}

.outline-children {
  display: none;
}

.info-panel-tab-wrapper {
  display: none;
}

.outline-item-open > .outline-children {
  display: block;
}

.typora-export .outline-item {
  padding-top: 1px;
  padding-bottom: 1px;
}

.typora-export .outline-item:hover {
  margin-right: -8px;
  border-right-width: 8px;
  border-right-style: solid;
  border-right-color: transparent;
}

.typora-export .outline-expander::before {
  content: "+";
  font-family: inherit;
  top: -1px;
}

.typora-export .outline-expander:hover::before,
.typora-export .outline-item-open > .outline-item > .outline-expander::before {
  content: "−";
}

.typora-export-collapse-outline .outline-children {
  display: none;
}

.typora-export-collapse-outline .outline-item-open > .outline-children,
.typora-export-no-collapse-outline .outline-children {
  display: block;
}

.typora-export-no-collapse-outline .outline-expander::before {
  content: "" !important;
}

.typora-export-show-outline
  .outline-item-active
  > .outline-item
  .outline-label {
  font-weight: 700;
}

.md-inline-math-container mjx-container {
  zoom: 0.95;
}

:root {
  --side-bar-bg-color: #fafafa;
  --control-text-color: #777;
}

html {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: "Open Sans", "Clear Sans", "Helvetica Neue", Helvetica, Arial,
    "Segoe UI Emoji", sans-serif;
  color: #333;
  line-height: 2;
}

#write {
  max-width: 860px;
  margin: 0 auto;
  padding: 30px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 1400px) {
  #write {
    max-width: 1024px;
  }
}

@media only screen and (min-width: 1800px) {
  #write {
    max-width: 1200px;
  }
}

#write > ol:first-child,
#write > ul:first-child {
  margin-top: 30px;
}

a {
  color: #4183c4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 700;
  line-height: 1.4;
  cursor: text;
}

h1:hover a.anchor,
h2:hover a.anchor,
h3:hover a.anchor,
h4:hover a.anchor,
h5:hover a.anchor,
h6:hover a.anchor {
  text-decoration: none;
}

h1 code,
h1 tt {
  font-size: inherit;
}

h2 code,
h2 tt {
  font-size: inherit;
}

h3 code,
h3 tt {
  font-size: inherit;
}

h4 code,
h4 tt {
  font-size: inherit;
}

h5 code,
h5 tt {
  font-size: inherit;
}

h6 code,
h6 tt {
  font-size: inherit;
}

h1 {
  font-size: 2.25em;
  line-height: 1.2;
  border-bottom: 1px solid #eee;
}

h2 {
  font-size: 1.75em;
  line-height: 1.225;
  border-bottom: 1px solid #eee;
}

h3 {
  font-size: 1.5em;
  line-height: 1.43;
}

h4 {
  font-size: 1.25em;
}

h5 {
  font-size: 1em;
}

h6 {
  font-size: 1em;
  color: #777;
}

blockquote,
dl,
ol,
p,
table,
ul {
  margin: 0.8em 0;
}

li > ol,
li > ul {
  margin: 0 0;
}

hr {
  height: 2px;
  padding: 0;
  margin: 16px 0;
  background-color: #e7e7e7;
  border: 0 none;
  overflow: hidden;
  box-sizing: content-box;
}

li p.first {
  display: inline-block;
}

ol:first-child,
ul:first-child {
  margin-top: 0;
}

ol:last-child,
ul:last-child {
  margin-bottom: 0;
}

blockquote {
  border-left: 4px solid #dfe2e5;
  padding: 0 15px;
  color: #777;
}

blockquote blockquote {
  padding-right: 0;
}

table {
  padding: 0;
  word-break: initial;
}

table tr {
  border: 1px solid #dfe2e5;
  margin: 0;
  padding: 0;
}

table tr:nth-child(2n),
thead {
  background-color: #f8f8f8;
}

table th {
  font-weight: 700;
  border: 1px solid #dfe2e5;
  border-bottom: 0;
  margin: 0;
  padding: 6px 13px;
}

table td {
  border: 1px solid #dfe2e5;
  margin: 0;
  padding: 6px 13px;
}

table td:first-child,
table th:first-child {
  margin-top: 0;
}

table td:last-child,
table th:last-child {
  margin-bottom: 0;
}

.CodeMirror-lines {
  padding-left: 4px;
}

.code-tooltip {
  box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.3);
  border-top: 1px solid #eef2f2;
}

.md-fences,
code,
tt {
  border: 1px solid #e7eaed;
  background-color: #f8f8f8;
  border-radius: 3px;
  padding: 0;
  padding: 2px 4px 0 4px;
  font-size: 0.9em;
}

code {
  background-color: #f3f4f4;
  padding: 0 2px 0 2px;
}

.md-fences {
  margin-bottom: 15px;
  margin-top: 15px;
  padding-top: 8px;
  padding-bottom: 6px;
}

.md-task-list-item > input {
  margin-left: -1.3em;
}

@media print {
  html {
    font-size: 13px;
  }

  pre,
  table {
    page-break-inside: avoid;
  }

  pre {
    word-wrap: break-word;
  }
}

.md-fences {
  background-color: #f8f8f8;
}

#write pre.md-meta-block {
  padding: 1rem;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f7f7f7;
  border: 0;
  border-radius: 3px;
  color: #777;
  margin-top: 0 !important;
}

.mathjax-block > .code-tooltip {
  bottom: 0.375rem;
}

.md-mathjax-midline {
  background: #fafafa;
}

#write > h3.md-focus:before {
  left: -1.5625rem;
  top: 0.375rem;
}

#write > h4.md-focus:before {
  left: -1.5625rem;
  top: 0.285714286rem;
}

#write > h5.md-focus:before {
  left: -1.5625rem;
  top: 0.285714286rem;
}

#write > h6.md-focus:before {
  left: -1.5625rem;
  top: 0.285714286rem;
}

.md-image > .md-meta {
  border-radius: 3px;
  padding: 2px 0 0 4px;
  font-size: 0.9em;
  color: inherit;
}

.md-tag {
  color: #a7a7a7;
  opacity: 1;
}

.md-toc {
  margin-top: 20px;
  padding-bottom: 20px;
}

.sidebar-tabs {
  border-bottom: none;
}

#typora-quick-open {
  border: 1px solid #ddd;
  background-color: #f8f8f8;
}

#typora-quick-open-item {
  background-color: #fafafa;
  border-color: #fefefe #e5e5e5 #e5e5e5 #eee;
  border-style: solid;
  border-width: 1px;
}

.on-focus-mode blockquote {
  border-left-color: rgba(85, 85, 85, 0.12);
}

.context-menu,
.megamenu-content,
footer,
header {
  font-family: "Segoe UI", Arial, sans-serif;
}

.file-node-content:hover .file-node-icon,
.file-node-content:hover .file-node-open-state {
  visibility: visible;
}

.mac-seamless-mode #typora-sidebar {
  background-color: #fafafa;
  background-color: var(--side-bar-bg-color);
}

.md-lang {
  color: #b4654d;
}

#md-notification .btn {
  border: 0;
}

.dropdown-menu .divider {
  border-color: #e5e5e5;
  opacity: 0.4;
}

.ty-preferences .window-content {
  background-color: #fafafa;
}

.ty-preferences .nav-group-item.active {
  color: #fff;
  background: #999;
}

.menu-item-container a.menu-style-btn {
  background-color: #f5f8fa;
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.8),
    hsla(0, 0%, 100%, 0)
  );
}

@media print {
  @page {
    margin: 0;
  }

  body.typora-export {
    padding-left: 0;
    padding-right: 0;
  }

  #write {
    padding: 0;
  }
}
</style>
