<template>
  <div class="index">
    <div class="downloadCode" style="background-color: white" v-if="isShow">
      <ul class="codeT">
        <li class="l_1">下载APP查看更多内容</li>
        <li class="l_2"><span>1</span>点击手机屏幕右上角</li>
        <li class="l_3"><img src="@/assets/mmbuy/download/appCodeT.jpg"></li>
        <li class="l_2"><span>2</span>选择用浏览器打开</li>
        <li class="l_4"><span><img src="@/assets/mmbuy/download/appCodeIcon.jpg"></span></li>
      </ul>
    </div>
    <div v-else class="container">
      <img src="@/assets/mmbuy/download/bk.png" alt="Logo" class="responsive-img" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DownloadView',
  data () {
    return {
      isShow: false,
      android_url: 'https://apk.qqspmmw.com/app-release.apk',
      ios_url: 'https://apps.apple.com/app/24%E5%B0%8F%E6%97%B6%E4%B9%B0%E5%8D%96%E7%BD%91/id6514280742?mt=8'
    }
  },
  created () {
    this.goDownload()
  },
  methods: {
    goDownload () {
      const u = navigator.userAgent
      const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1
      const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      // 这里是安卓浏览器
      if (isAndroid) {
        if (this.isWeiXin()) {
          this.isShow = true
        } else {
          window.location.href = this.android_url + '?'+this.generateRandom32CharMD5Like() // 跳安卓端下载地址
        }
      }
      // 这里是iOS浏览器
      if (isIOS) {
        // alert('AppStore 审核中尽请期待！')
        window.location.href = this.ios_url // 跳AppStore下载地址
      }
    },
    isWeiXin () {
      var ua = window.navigator.userAgent.toLowerCase()
      // eslint-disable-next-line eqeqeq
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true
      } else {
        return false
      }
    },
    generateRandom32CharMD5Like() {
      const chars = '0123456789abcdef'.split('');
      let result = '';

      for (let i = 0; i < 32; i++) { // 32 characters, each picked randomly from 0-9, a-f
        result += chars[Math.floor(Math.random() * chars.length)];
      }

      return result;
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* 让容器占满整个视口高度 */
  background-color: #f0f0f0; /* 背景颜色 */
  text-align: center;
  background: linear-gradient(to bottom, #DC3023, #FE5534); /* 背景渐变 */
}

.responsive-img {
  width: auto; /* 设置图片宽度 */
  height: 100%; /* 高度自适应 */
  object-fit: contain; /* 保持图片比例，不变形 */
}

.downloadCode img{ max-width: 100%;}
.downloadCode{ overflow: hidden; font-family:"Microsoft YaHei"}
.codeT{width:100%; text-align: center;}
.codeT li{ margin-top: 38pt; text-align: center; display: ruby-text;}
</style>
