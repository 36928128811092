<template>
  <!-- 关于我们 内容界面 -->

  <div class="about">
    <section class="px-0 lg:px-0 pt-28 mx-auto">
      <img src="@/assets/mmbuy/about/Mask01.png" alt="" class="w-full h-auto" />
    </section>

    <div class="md:flex max-w-screen-1200 mx-auto md:mt-8 mb-12">
      <!-- 左侧导航栏 start-->
      <div class="w-full md:w-1/4">
        <div class="p-6 h-18 m-0 bg-red-700 text-2xl">
          <div class="text-white font-bold">关于我们</div>
        </div>

        <div
          @click="setActiveTab('tab1')"
          :class="{
            'bg-gray-100': activeTab !== 'tab1',
            'text-primary': activeTab === 'tab1',
            flex: true,
          }"
          class="flex items-center border-b border-gray-200"
        >
          <button
            class="w-full h-16 py-2 px-4 text-left rounded focus:outline-none text-lg"
          >
            董事长的初心
          </button>
          <img
            class="ml-auto pr-3"
            :src="
              activeTab === 'tab1'
                ? require('@/assets/mmbuy/about/Group1.png')
                : require('@/assets/mmbuy/about/Group0.png')
            "
            alt="图片"
          />
        </div>

        <div
          @click="setActiveTab('tab2')"
          :class="{
            'bg-gray-100': activeTab !== 'tab2',
            'text-primary': activeTab === 'tab2',
            flex: true,
          }"
          class="flex items-center border-b border-gray-200"
        >
          <button
            class="w-full h-16 py-2 px-4 text-left rounded focus:outline-none text-lg"
          >
            公司简介
          </button>
          <img
            class="ml-auto pr-3"
            :src="
              activeTab === 'tab2'
                ? require('@/assets/mmbuy/about/Group1.png')
                : require('@/assets/mmbuy/about/Group0.png')
            "
            alt="图片"
          />
        </div>

        <div
          @click="setActiveTab('tab3')"
          :class="{
            'bg-gray-100': activeTab !== 'tab3',
            'text-primary': activeTab === 'tab3',
            flex: true,
          }"
          class="flex items-center border-b border-gray-200"
        >
          <button
            class="w-full h-16 py-2 px-4 text-left rounded focus:outline-none text-lg"
          >
            企业领导
          </button>
          <img
            class="ml-auto pr-3"
            :src="
              activeTab === 'tab3'
                ? require('@/assets/mmbuy/about/Group1.png')
                : require('@/assets/mmbuy/about/Group0.png')
            "
            alt="图片"
          />
        </div>

        <div
          @click="setActiveTab('tab7')"
          :class="{
            'bg-gray-100': activeTab !== 'tab7',
            'text-primary': activeTab === 'tab7',
            flex: true,
          }"
          class="flex items-center border-b border-gray-200"
        >
          <button
            class="w-full h-16 py-2 px-4 text-left rounded focus:outline-none text-lg"
          >
            顾问团领导
          </button>
          <img
            class="ml-auto pr-3"
            :src="
              activeTab === 'tab7'
                ? require('@/assets/mmbuy/about/Group1.png')
                : require('@/assets/mmbuy/about/Group0.png')
            "
            alt=""
          />
        </div>

        <div
          @click="setActiveTab('tab4')"
          :class="{
            'bg-gray-100': activeTab !== 'tab4',
            'text-primary': activeTab === 'tab4',
            flex: true,
          }"
          class="flex items-center border-b border-gray-200"
        >
          <button
            class="w-full h-16 py-2 px-4 text-left rounded focus:outline-none text-lg"
          >
            领导团队
          </button>
          <img
            class="ml-auto pr-3"
            :src="
              activeTab === 'tab4'
                ? require('@/assets/mmbuy/about/Group1.png')
                : require('@/assets/mmbuy/about/Group0.png')
            "
            alt="图片"
          />
        </div>

        <div
          @click="setActiveTab('tab5')"
          :class="{
            'bg-gray-100': activeTab !== 'tab5',
            'text-primary': activeTab === 'tab5',
            flex: true,
          }"
          class="flex items-center border-b border-gray-200"
        >
          <button
            class="w-full h-16 py-2 px-4 text-left rounded focus:outline-none text-lg"
          >
            组织架构
          </button>
          <img
            class="ml-auto pr-3"
            :src="
              activeTab === 'tab5'
                ? require('@/assets/mmbuy/about/Group1.png')
                : require('@/assets/mmbuy/about/Group0.png')
            "
            alt="图片"
          />
        </div>

        <div
          @click="setActiveTab('tab6')"
          :class="{
            'bg-gray-100': activeTab !== 'tab6',
            'text-primary': activeTab === 'tab6',
            flex: true,
          }"
          class="flex items-center border-b border-gray-200"
        >
          <button
            class="w-full h-16 py-2 px-4 text-left rounded focus:outline-none text-lg"
          >
            企业文化
          </button>
          <img
            class="ml-auto pr-3"
            :src="
              activeTab === 'tab6'
                ? require('@/assets/mmbuy/about/Group1.png')
                : require('@/assets/mmbuy/about/Group0.png')
            "
            alt="图片"
          />
        </div>
      </div>

      <!-- 左侧导航栏 end-->

      <!-- 右侧内容区域 -->
      <!-- <div class="flex-1 pl-8 pr-8"> -->
      <div class="w-full md:w-3/4 pl-[5%] pr-[5%]">
        <div v-if="activeTab === 'tab1'">
          <div
            class="h-20 flex items-center justify-between border-b border-b-gray-1"
          >
            <span class="hidden md:block font-bold text-2xl">董事长的初心</span>
            <span class="text-right text-sm"
              >当前位置：关于我们 &gt; 董事长的初心</span
            >
          </div>

          <!-- <h2 class="text-3xl mb-4  text-primary font-bold mt-8">要有全球的眼界，创新超前的思维</h2> -->
          <img src="@/assets/mmbuy/about/yinhao1.png" class="mt-8" />

          <p class="text-3xl font-bold mt-6 text-center tracking-[.2em]">
            要有全球的眼界，创新超前的思维
          </p>
          <p class="text-3xl font-bold mt-4 text-center tracking-[.2em]">
            充分认识互联网平台的作用和价值
          </p>
          <p class="text-3xl font-bold mt-4 text-center tracking-[.52em] pl-2">
            建设好、使用好互联网平台
          </p>
          <p class="text-3xl font-bold mt-4 md-6 text-center tracking-[.06em]">
            用互联网解决各种问题，创造更多财富
          </p>

          <p class="text-xl leading-10 indent-10 mt-8 bg-gray-100 p-6">
            我认为，一个好的互联网平台，能“养活”无数个企业和个人；能“救活”无数个企业和个人；能解决无数个企业、个人生存和发展问题；能为政府分忧解难；能带动一方经济，乃至全国经济、世界经济的发展和腾飞。
          </p>

          <p class="text-xl leading-10 indent-10 mt-4">
            我原在政府机关是一名公务员，后来辞职下海经商。2016年开始，任大连黑龙江省商会副会长一职多年，后来又荣入黑龙江省《龙商总会》，成为一员。
          </p>
          <p class="text-xl leading-10 indent-10 mt-4">
            多年来，在工作中接触全国多地省、市、县、区的各级领导、各类企业、各行各业企业家、个体工商户、商会同仁、亲朋好友、以及国外友人的过程中，大家都有一个普遍共同的愿望和需求，那就是：
          </p>
          <p class="text-xl leading-10 indent-10">
            1．需要我们帮助政府招商引资；
          </p>
          <p class="text-xl leading-10 indent-10">
            2．需要我们帮助企业和个人销售产品；
          </p>
          <p class="text-xl leading-10 indent-10">
            3．需要我们找好的有价值的项目投资；
          </p>
          <p class="text-xl leading-10 indent-10">
            4．需要我们帮助企业找人才和劳动力；
          </p>
          <p class="text-xl leading-10 indent-10">
            5．需要我们帮助政府和企业做推介宣传；
          </p>
          <p class="text-xl leading-10 indent-10">等等各种工作需求……</p>

          <p class="text-xl leading-10 indent-10 mt-4">
            例如，有的县政府领导亲自邀约我去他们县洽谈招商引资等工作；有的国企领导、私企老板邀约我去他们企业考察、洽谈销售产品，等等需求，举不胜举。
          </p>

          <p class="text-xl leading-10 indent-10 mt-4">
            为了不辜负政府领导、国企领导和私企老板及朋友们的信任和重托，针对以上愿望和需求，我在思考：如何才能帮助政府、企业、企业家、个体工商户、商会同仁、亲朋好友、以及国外友人等解决以上工作需求问题，同时我在思考，如何发展我的自身企业......
          </p>

          <p class="text-xl leading-10 indent-10 mt-4">
            我想，用传统的思维模式、商业模式，线下人找人的方式方法，效率极低，事倍功半，浪费人财物；即使举全公司、全家之力，也是杯水车薪，也解决不了以上需求问题。经过深思熟虑后，得出结论：我认为“互联网”具有强大无比的力量，具有解决以上各种需求的潜能，具有无限美好的前景！因此，我决定用互联网思维、平台经济思维、科技思维，进军“互联网”产业，决定创新创建《中国全球商品买卖网》（供需）大平台。
          </p>

          <p class="text-xl leading-10 indent-10 mt-4">
            经过长期用心的策划、设计、规划，经过不懈的努力，平台终于正式上线运营（部分）。今天展现在大家面前！
          </p>
          <p class="text-xl leading-10 indent-10 mt-4">
            我们创建的《中国全球商品买卖网》（供需）大平台，是一个创新的、多功能的、综合服务的、世界级的，为全球各个国家、各级政府、各类企业和个人提供综合服务的互联网大平台；是一个为政府、企业、个人解决商海里问题和困难的大平台；是一个万亿级的大平台！平台的想象空间、创意空间、设计空间、市场空间、实战空间、未来发展空间无限美好！
          </p>

          <p class="text-xl leading-10 indent-10 mt-8 font-bold">
            《中国全球商品买卖网》的服务功能
          </p>

          <p class="text-xl leading-10 indent-10 mt-4">—— 全球商品买卖</p>
          <p class="text-xl leading-10 indent-10 mt-4">—— 全球资产买卖</p>
          <p class="text-xl leading-10 indent-10 mt-4">
            —— 全球人才、劳动力找工作
          </p>
          <p class="text-xl leading-10 indent-10 mt-4">
            —— 全球做广告，广告做全球
          </p>
          <p class="text-xl leading-10 indent-10 mt-4">
            —— 全球招商引资，为政府、企业对接项目
          </p>
          <p class="text-xl leading-10 indent-10 mt-4">—— 全球商协会交流合作</p>
          <p class="text-xl leading-10 indent-10 mt-4">—— 全球“网红之家”</p>
          <p class="text-xl leading-10 indent-10 mt-4">
            —— 全球互帮互助爱心平台
          </p>

          <p class="text-xl leading-10 indent-10 mt-4">
            —— 全球产品品牌营销策划
          </p>

          <p class="text-xl leading-10 indent-10 mt-4">—— 全球企业家交友</p>

          <p class="text-xl leading-10 indent-10 mt-8 font-bold">
            《中国全球商品买卖网》的目标
          </p>

          <p class="text-xl leading-10 indent-10 mt-4">
            —— 打造成，“买全球、卖全球”，世界知名品牌
          </p>
          <p class="text-xl leading-10 indent-10 mt-4">
            —— 打造成，万亿级营销额的大平台
          </p>
          <p class="text-xl leading-10 indent-10 mt-4">
            —— 打造成，千亿级利税额的公司
          </p>
          <p class="text-xl leading-10 indent-10 mt-4">
            —— 打造成，千亿级市值的上市公司
          </p>
          <p class="text-xl leading-10 indent-10 mt-4">
            —— 打造成，全球50%以上的人口使用这个大平台
          </p>
          <p class="text-xl leading-10 indent-10 mt-4">
            —— 打造成，让全球人参与、为全球人服务的大平台
          </p>
          <p class="text-xl leading-10 indent-10 mt-4">
            —— 打造成，百年科技企业
          </p>
          <p class="text-xl leading-10 indent-10 mt-4">
            —— 打造成，推动东北经济、推动中国经济、推动世界经济快速发展的新引擎
          </p>

          <p class="text-xl leading-10 indent-10 mt-4">
            ——
            平台落户东北，填补东北三省没有大型互联网电商大平台的空白，提升东北三省在中国、在世界的知名度、影响力和地区价值
          </p>

          <p class="text-xl leading-10 indent-10 mt-8 font-bold">
            《中国全球商品买卖网》的意义
          </p>

          <p class="text-xl leading-10 indent-10 mt-4">
            —— 为习总书记提出的“一带一路”建设，插上了互联网电商科技的翅膀！
          </p>
          <p class="text-xl leading-10 indent-10 mt-4">
            ——
            为落实习总书记提出的“网络强国”、“新质生产力”思想理论和战略目标，贡献我们的力量！
          </p>
          <p class="text-xl leading-10 indent-10 mt-4">
            ——
            为东北经济、为中国经济、为世界经济的快速发展注入新动能、带来新活力，取得新成就！
          </p>
          <p class="text-xl leading-10 indent-10 mt-4">
            ——
            从全球来讲，有利于世界国与国之间、企业与企业之间、人与人之间的交流、合作；有利于世界人与人之间建立深厚的“感情”；有利于建设和平、和谐、美好的世界！
          </p>

          <p class="text-xl leading-10 indent-10 mt-4">
            尽管当今世界互联网供需平台众多，但不能因为平台众多，我们就不再建设新平台了，自然法则是优胜劣汰，只要我们创新、创新、再创新，创造出有价值、有市场需求、有社会需求、有全球需求的“新产品”、“好产品”，就一定会有大发展，就一定会取得新成就！
          </p>

          <p class="text-xl leading-10 indent-10 mt-4">
            从我国的实际情况来讲，我国是个大国，我国一个省的人口数量和土地面积，相当于国外一个中小国家的体量，因此，我们每个省都应该建设一个本省的、有体量、有规模、有知名度、有影响力、面向全球的互联网（供需）大平台！用来向全球宣传、推广、销售本省的“名、优、特”产品；用来向全球宣传、展示、推介本省......
          </p>

          <p class="text-xl leading-10 indent-10 mt-4">
            纵观历史，无论是我国“抗震救灾”，还是“抗洪抢险”；无论是我国“抗击新冠疫情”，还是全球“抗击新冠疫情”，都离不开互联网，互联网发挥了不可替代的重大作用。互联网做出了巨大无比的“贡献”！
          </p>
          <p class="text-xl leading-10 indent-10 mt-4">
            纵观全球，无论是过去、现在、还是未来，世界各国的政治、经济、文化、科技、军事等都离不开互联网，世界各国人民的工作、学习、生活也都离不开互联网，因为创建和使用互联网，给各个国家带来了全面的发展和进步，给各国人民的工作、学习、生活，带来了物质和精神的收获，带来了无限的美好！
          </p>

          <p class="text-xl leading-10 indent-10 mt-4">
            实践证明，网络能强“人”！网络能强企业！网络能强乡（镇）、强县（区）、强市、强省、强国、强世界！
          </p>
          <p class="text-xl leading-10 indent-10 mt-4">
            因此，我们要有全球的眼界，创新超前的思维，充分认识互联网平台的作用和价值，建设好、使用好互联网平台，用互联网解决各种问题，创造更多财富。
          </p>
          <p class="text-xl leading-10 indent-10 mt-4">
            我们愿同全球各国人民团结一心，携手合作，共创、共赢、共享，共同打造好这个全球独一无二的、让全球人参与、为全球人服务的《中国全球商品买卖网》（供需）大平台。
          </p>
          <p class="text-xl leading-10 indent-10 mt-4">
            我们相信《中国全球商品买卖网》这个（供需）大平台，一定会给全球各个国家和人民带来无限的美好！让世界充满爱！
          </p>

          <!-- <div class=" mt-8 object-right">
            <img src="@/assets/mmbuy/about/yinhao2.png" class="object-right" />
          </div> -->

          <img
            class="object-none object-right w-full h-24"
            src="@/assets/mmbuy/about/yinhao2.png"
          />

          <div class="md:float-right">
            <img
              class="object-none object-center w-full h-10"
              src="@/assets/mmbuy/about/qianming.png"
            />
            <p class="text-2xl text-right mt-6 leading-10">
              中国全球商品买卖网董事长、总裁
            </p>
          </div>
        </div>

        <div v-if="activeTab === 'tab2'" class="justify-center">
          <div
            class="h-20 flex items-center justify-between border-b border-b-gray-1"
          >
            <span class="hidden md:block font-bold text-2xl">公司简介</span>

            <span class="text-right text-sm"
              >当前位置：关于我们 &gt; 公司简介</span
            >
          </div>

          <h2 class="text-3xl mb-4 text-primary font-bold mt-8 text-center">
            中国全球商品买卖网有限公司
          </h2>
          <p class="text-xl leading-10 text-indent">
            &nbsp;&nbsp;&nbsp;&nbsp;
            我们创建的《中国全球商品买卖网》（供需）大平台，是一个创新的、多功能的、综合服务的、世界级的，为全球各个国家、各级政府、各类企业和个人提供综合服务的互联网大平台；是一个为政府、企业、个人解决商海里问题和困难的大平台；是万亿级的大平台！平台的想象空间、创意空间、市场空间、实战空间、发展空间无限美好！
          </p>
          <p class="text-xl leading-10">
            &nbsp;&nbsp;&nbsp;&nbsp;
            平台具有全球商品买卖；全球资产买卖；全球人才、劳动力找工
            作；全球做广告，广告做全球；全球招商引资，为政府、企业对接项目；全球商协会交流合作；全球“网红之家”；全球互帮互助爱心平台。
          </p>
          <p class="text-xl leading-10">
            &nbsp;&nbsp;&nbsp;&nbsp;
            将世界各个国家、企业、个人的工业产品、农业产品、服务业产品、科技产品、文化旅游产品等，均在《中国全球商品买卖网》的线上平台展示销售；同时创建线下旗舰店展示销售产品。实现线上、线下双轮驱动。
          </p>

          <p class="text-xl leading-10">
            &nbsp;&nbsp;&nbsp;&nbsp; （1）线上：互联网电商平台+短视频+直播。
          </p>

          <p class="text-xl leading-10">
            &nbsp;&nbsp;&nbsp;&nbsp;
            （2）线下：建设实体旗舰店，一期每个省会城市建设一家旗舰店，未来全国发展加盟店一万家，全球发展加盟店百万家！
          </p>

          <p class="text-xl leading-10">
            &nbsp;&nbsp;&nbsp;&nbsp;
            通过线上线下联动，将中国产品推向世界！将世界产品引进中国！将各个国家的产品推向世界！将《中国全球商品买卖网》做成永不落幕的展会，永不沉没的航母！实现“买全球、卖全球”！
          </p>
        </div>

        <div v-if="activeTab === 'tab3'">
          <div
            class="h-20 flex items-center justify-between border-b border-b-gray-1"
          >
            <span class="hidden md:block font-bold text-2xl">企业领导</span>
            <span class="text-right text-sm"
              >当前位置：关于我们 &gt; 企业领导</span
            >
          </div>

          <div class="md:flex items-center mt-8">
            <div class="bg-cover bg-center relative">
              <!-- 实际内容或加载后的图片 -->
              <img src="@/assets/mmbuy/about/Leader01.jpg" alt="" />
            </div>
            <div class="ml-auto max-w-3/4">
              <h1 class="text-2xl font-bold">李京</h1>
              <p class="text-xl mt-2 leading-10">
                中国全球商品买卖网有限公司董事长
              </p>
              <p class="text-xl leading-10">大连中欧科技集团有限公司董事长</p>
              <p class="text-xl leading-10">黑龙江省龙商总会会员</p>
            </div>
          </div>
        </div>

        <div v-if="activeTab === 'tab7'">
          <div
            class="h-20 flex items-center justify-between border-b border-b-gray-1"
          >
            <span class="hidden md:block font-bold text-2xl">顾问团领导</span>
            <span class="text-right text-sm"
              >当前位置：关于我们 &gt; 顾问团领导</span
            >
          </div>

          <div class="flex items-center justify-center mt-4">
            <div
              class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 mb-4"
            >
              <div
                v-for="(person, index) in guwen"
                :key="index"
                class="p-4 flex flex-col items-center"
              >
                <img class="object-cover" :src="person.image" alt="Avatar" />
                <h2 class="mt-4 text-xl font-semibold">{{ person.name }}</h2>
                <p class="text-gray-500 text-center">{{ person.title }}</p>
                

              </div>
            </div>
          </div>
        </div>

        <div v-if="activeTab === 'tab4'">
          <div
            class="h-20 flex items-center justify-between border-b border-b-gray-1"
          >
            <span class="hidden md:block font-bold text-2xl">领导团队</span>
            <span class="text-right text-sm"
              >当前位置：关于我们 &gt; 领导团队</span
            >
          </div>

          <div class="flex items-center justify-center mt-4">
            <div class="flex flex-col items-center">
              <!-- 个人信息部分 -->
              <div class="p-4 flex flex-col items-center">
                <img
                  class="object-cover lg:w-1/2"
                  src="@/assets/mmbuy/about/lijing.jpg"
                  alt="Avatar"
                />
                <h2 class="mt-4 text-xl font-semibold">李京</h2>
                <p class="text-gray-500 leading-10">董事长</p>
              </div>

              <div class="p-4 flex flex-col items-center">
                <img
                  class="object-cover lg:w-1/2"
                  src="@/assets/mmbuy/about/langshengqi.jpg"
                  alt="Avatar"
                />
                <h2 class="mt-4 text-xl font-semibold">郎聖淇</h2>
                <p class="text-gray-500 leading-10">总裁</p>
              </div>

              <!-- 线条部分 -->
              <div class="relative w-full max-w-3xl mt-4 invisible md:visible">
                <!-- 上面的竖线 -->
                <div
                  class="absolute left-1/2 transform -translate-x-1/2 -top-4 h-4 w-0.5 bg-gray-200"
                ></div>

                <!-- 横线 -->
                <div class="w-3/4 h-0.5 bg-gray-200 mx-auto"></div>

                <div
                  class="h-4 w-0.5 bg-gray-200 absolute -bottom-4"
                  style="left: 12.5%"
                ></div>
                <div
                  class="h-4 w-0.5 bg-gray-200 absolute -bottom-4"
                  style="left: 37.22%"
                ></div>
                <div
                  class="h-4 w-0.5 bg-gray-200 absolute -bottom-4"
                  style="left: 62.22%"
                ></div>
                <div
                  class="h-4 w-0.5 bg-gray-200 absolute -bottom-4"
                  style="left: 87.22%"
                ></div>
              </div>

              <div class="container mx-auto py-8">
                <div
                  class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 mb-4"
                >
                  <div
                    v-for="(person, index) in people"
                    :key="index"
                    class="p-4 flex flex-col items-center"
                  >
                    <img
                      class="object-cover"
                      :src="person.image"
                      alt="Avatar"
                    />
                    <h2 class="mt-4 text-xl font-semibold">
                      {{ person.name }}
                    </h2>
                    <p class="text-gray-500">{{ person.title }}</p>
                    <p class="text-gray-500">{{ person.title1 }}</p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="activeTab === 'tab5'">
          <div
            class="h-20 flex items-center justify-between border-b border-b-gray-1"
          >
            <span class="hidden md:block font-bold text-2xl">组织架构</span>
            <span class="text-right text-sm"
              >当前位置：关于我们 &gt; 组织架构</span
            >
          </div>

          <img src="@/assets/mmbuy/about/Group05.png" class="mt-8" />
        </div>

        <div v-if="activeTab === 'tab6'">
          <div
            class="h-20 flex items-center justify-between border-b border-b-gray-1"
          >
            <span class="hidden md:block font-bold text-2xl">企业文化</span>
            <span class="text-right text-sm"
              >当前位置：关于我们 &gt; 企业文化</span
            >
          </div>

          <p class="text-xl mt-8">品牌理念</p>
          <p class="text-xl mt-4">—— 买全球，卖全球</p>

          <p class="text-xl mt-16">企业使命</p>
          <p class="text-xl mt-4">—— 让全球人参与，为全球人服务</p>

          <p class="text-xl mt-16">企业愿景</p>
          <p class="text-xl mt-4">—— 成为世界知名品牌、百年科技企业</p>

          <p class="text-xl mt-16">核心价值观</p>
          <p class="text-xl mt-4">—— 以价值创造为本，成就你我他（她）</p>

          <p class="text-xl mt-16">经营理念</p>
          <p class="text-xl mt-4">—— 创新发展 双轮驱动</p>

          <p class="text-xl mt-16">人才理念</p>
          <p class="text-xl mt-4">
            —— 为敬业者搭建平台，为成才者培植沃土，培养全能型人才
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutView",
  components: {},
  created() {
    if (
      this.$route.params.activeTab !== undefined &&
      this.$route.params.activeTab !== ""
    )
      this.activeTab = "tab" + this.$route.params.activeTab;
  },
  data() {
    return {
      activeTab: "tab1",
      people: [
        {
          name: "马亮",
          title: "常务副总裁",
          title1: "",
          image: require("@/assets/mmbuy/about/maliang.png"),
        },
        {
          name: "郑晓月",
          title: "副总裁",
          title1: "（技术）",
          image: require("@/assets/mmbuy/about/zhengxiaoyue.png"),
        },
        {
          name: "宋棋",
          title: "副总裁",
          title1: "（全球运营）",
          image: require("@/assets/mmbuy/about/songqi.png"),
        },
        {
          name: "梁振",
          title: "副总裁",
          title1: "（发展规划）",
          image: require("@/assets/mmbuy/about/liangzhen.png"),
        },
        {
          name: "李海燕",
          title: "副总裁",
          title1: "（董办）",
          image: require("@/assets/mmbuy/about/lihaiyan.png"),
        },
        
        {
          name: "唐传洁",
          title: "副总裁",
          title1: "（广告）",
          image: require("@/assets/mmbuy/about/tangchuanjie.png"),
        },
        {
          name: "王丹",
          title: "副总裁",
          title1: "（招商引资）",
          image: require("@/assets/mmbuy/about/wangdan.png"),
        },
       
        {
          name: "成静",
          title: "副总裁",
          title1: "（网红协会）",
          image: require("@/assets/mmbuy/about/chengjing.png"),
        },
        {
          name: "陈雨",
          title: "副总裁",
          title1: "（商协会）",
          image: require("@/assets/mmbuy/about/chenyu.png"),
        },
        {
          name: "李阿丹",
          title: "副总裁",
          title1: "（人力资源）",
          image: require("@/assets/mmbuy/about/liadan.png"),
        },
        {
          name: "谭伟英",
          title: "副总裁",
          title1: "（法务）",
          image: require("@/assets/mmbuy/about/tanweiying.png"),
        },
        {
          name: "康勇",
          title: "副总裁",
          title1: "（企业家交友）",

          image: require("@/assets/mmbuy/about/kangyong.png"),
        },
        {
          name: "王晓光",
          title: "副总裁",
          title1: "（财务）",
          image: require("@/assets/mmbuy/about/wangxiaoguang.png"),
        },
        {
          name: "唐渼橞",
          title: "副总裁",
          title1: "（资产买卖）",
          image: require("@/assets/mmbuy/about/tangmeihui.png"),
        },
        {
          name: "李枫杰",
          title: "副总裁",
          title1: "（电商运营）",
          image: require("@/assets/mmbuy/about/lifengjie.jpg"),
        },
      ],
      guwen: [
        {
          name: "马玉学",
          title: "辽宁省黑龙江商会会长",
          image: require("@/assets/mmbuy/about/mayuxue.png"),
        },
        {
          name: "徐景海",
          title: "天津市黑龙江商会会长",
          image: require("@/assets/mmbuy/about/xujinghai.png"),
        },
        {
          name: "刘泽龙",
          title: "山东省黑龙江商会会长",
          image: require("@/assets/mmbuy/about/liuzelong.png"),
        },
        {
          name: "张平刚",
          title: "江苏省黑龙江商会会长",
          image: require("@/assets/mmbuy/about/zhangpinggang.png"),
        },
        {
          name: "马广闻",
          title: "河北省黑龙江商会会长",
          image: require("@/assets/mmbuy/about/maguangwen.png"),
        },
      ],
    };
  },
  watch: {
    "$route.params.activeTab"(newValue) {
      // console.log("activeTab",newValue);
      this.activeTab = "tab" + newValue; // 如果你确实需要这个变量的话
    },
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>

<style scoped></style>
