<!-- 第三方信息共享清单 -->

<template>
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width initial-scale=1" />
    <link
      href="https://fonts.loli.net/css?family=Open+Sans:400italic,700italic,700,400&subset=latin,latin-ext"
      rel="stylesheet"
      type="text/css"
    />
    <title>SDK&amp;SDK_android</title>
  </head>

 
    <div class="typora-export">
      <div class="typora-export-content">
        <div id="write" class="">
          <h3 id="第三方信息共享清单"><span>第三方信息共享清单</span></h3>
          <p>
            <span
              >为保障万能小组件App的稳定运行或实现相关功能，我们可能会接入由第三方提供的软件开发包（SDK）实现前述目的。我们会尽到审慎义务，对合作方获取信息的软件工具开发包（SDK）进行严格的安全监测，以保护数据安全。</span
            >
            <span
              >我们接入的第三方SDK主要服务于您以及其他的用户的需求，因此在满足新的服务需求及业务功能变更时，我们可能会调整我们接入的第三方SDK。我们会及时在本说明中向您公开说明接入第三方SDK的最新情况。目前万能小组件App接入的第三方SDK列明如下：</span
            >
          </p>
    
          <p>
            <strong><span>友盟+SDK</span></strong>
            <span>使用目的：为App提供统计分析、分享服务</span>
            <span
              >使用场景：APP初始化、前台、后台运行时、分享时、运行时使用、</span
            >
            <span
              >涉及个人信息：设备信息（IMEI/MAC/Android
              ID/IDFA/OpenUDID/GUID/SIM卡IMSI/地理位置）</span
            >
            <span>收集方式：SDK采集</span>
            <span>合作方主体： 友盟同欣（北京）科技有限公司</span>
            <span>合作方官网连接：</span
            ><a href="https://www.umeng.com/" target="_blank" class="url"
              >https://www.umeng.com/</a
            >
            <span>合作方隐私政策：</span
            ><a
              href="https://www.umeng.com/page/policy?spm=a211eg.10560647.0.0.547034dcafEUZJ"
              target="_blank"
              class="url"
              >https://www.umeng.com/page/policy?spm=a211eg.10560647.0.0.547034dcafEUZJ</a
            >
          </p>
          <!-- <p>
            <strong><span>优量汇</span></strong>
            <span>使用目的：广告投放、广告归因、广告监测、反作弊</span>
            <span
              >使用场景：APP初始化、前台、后台运行时、使用APP时展示广告内容</span
            >
            <span
              >涉及个人信息：设备标识信息（OAID、IMEI、AndroidID）、网络信息（连接的WIFI、MAC地址）、加速度传感器、陀螺仪传感器</span
            >
            <span>收集方式：SDK采集</span>
            <span>合作方主体： 腾讯公司</span>
            <span>合作方官网连接：</span
            ><a
              href="https://e.qq.com/dev/index.html"
              target="_blank"
              class="url"
              >https://e.qq.com/dev/index.html</a
            >
            <span>合作方隐私政策：</span
            ><a
              href="https://e.qq.com/dev/help_detail.html?cid=2005&amp;pid=5983"
              target="_blank"
              class="url"
              >https://e.qq.com/dev/help_detail.html?cid=2005&pid=5983</a
            >
          </p> -->
          <p>
            <strong><span>QQ互联&amp;腾讯开放平台</span></strong>
            <span>使用目的：分享至QQ、QQ空间</span>
            <span>使用场景: 在登录APP和分享组件时使用</span>
            <span
              >涉及个人信息：设备标识符（Android如IMEI、Android
              ID、IMSI、ICCID、MEID，iOS如IDFV）、MAC地址、IP地址、位置信息（如GPS、蓝牙）、WLAN接入点（如SSID、BSSID）、分享图片时间写入存储权限来实现分享内容、软件列表、设备传感器信息</span
            >
            <span>收集方式：SDK采集</span>
            <span>合作方主体： 腾讯公司</span>
            <span>合作方官网连接：</span
            ><a href="https://connect.qq.com/" target="_blank" class="url"
              >https://connect.qq.com/</a
            >
            <span>合作方隐私政策：</span
            ><a
              href="https://wiki.connect.qq.com/qq%e4%ba%92%e8%81%94sdk%e9%9a%90%e7%a7%81%e4%bf%9d%e6%8a%a4%e5%a3%b0%e6%98%8e"
              target="_blank"
              class="url"
              >https://wiki.connect.qq.com/qq%e4%ba%92%e8%81%94sdk%e9%9a%90%e7%a7%81%e4%bf%9d%e6%8a%a4%e5%a3%b0%e6%98%8e</a
            >
          </p>
          <p>
            <strong><span>微信开放平台</span></strong>
            <span>使用目的：微信分享服务</span>
            <span>使用场景：在登录APP和分享组件时使用</span>
            <span
              >涉及个人信息：操作系统信息、设备型号信息、相册、内存卡权限、应用列表信息、微信头像、微信昵称</span
            >
            <span>收集方式：SDK采集</span>
            <span>合作方主体： 腾讯公司</span>
            <span>合作方官网连接：</span
            ><a href="https://open.weixin.qq.com/" target="_blank" class="url"
              >https://open.weixin.qq.com/</a
            >
            <span>合作方隐私政策：</span
            ><a
              href="https://open.weixin.qq.com/cgi-bin/frame?t=news/protocol_developer_tmpl"
              target="_blank"
              class="url"
              >https://open.weixin.qq.com/cgi-bin/frame?t=news/protocol_developer_tmpl</a
            >
          </p>
          <p>
            <strong><span>极光PUSH</span></strong>
            <span
              >使用目的：消息推送服务，实现多渠道、多通道的统一消息发送、管理和统计，帮助提升推送消息触达和保障系统运行稳定</span
            >
            <span>使用场景：APP初始化、前台、接收推信消息时使用</span>
            <span
              >涉及个人信息：IMEI、IDFA、Android ID、GID、
              MAC、OAID、VAID、AAID、IMSI、MEID、UAID、SN、ICCID、SIM信息，IP地址、WiFi信息、基站信息、应用列表、DNS地址、DHCP地址、SSID、BSSID。</span
            >
            <span>收集方式：SDK采集</span>
            <span>合作方主体： 深圳市和讯华谷信息技术有限公司</span>
            <span>合作方官网连接：</span
            ><a href="https://www.jiguang.cn/" target="_blank" class="url"
              >https://www.jiguang.cn/</a
            >
            <span>合作方隐私政策：</span
            ><a
              href="https://www.jiguang.cn/license/privacy"
              target="_blank"
              class="url"
              >https://www.jiguang.cn/license/privacy</a
            >
          </p>
          <p>
            <strong><span>小米 PUSH</span></strong>
            <span>使用目的：推送消息</span>
            <span>使用场景：在推送消息时使用</span>
            <span
              >涉及个人信息：设备标识符（如Android
              ID、OAID）、应用信息、设备信息、网络信息（本地处理）</span
            >
            <span>收集方式：SDK采集</span>
            <span>合作方主体：北京小米移动软件有限公司</span>
            <span>合作方官网链接：</span
            ><a
              href="https://dev.mi.com/console/appservice/push.html"
              target="_blank"
              class="url"
              >https://dev.mi.com/console/appservice/push.html</a
            >
            <span>合作方隐私政策：</span
            ><a
              href="https://dev.mi.com/console/doc/detail?pId=1822"
              target="_blank"
              class="url"
              >https://dev.mi.com/console/doc/detail?pId=1822</a
            >
          </p>
          <p>
            <strong><span>VIVO PUSH</span></strong>
            <span>使用目的：推送消息</span>
            <span>使用场景：在VIVO手机终端推送消息时使用</span>
            <span>涉及个人信息：设备信息</span>
            <span>收集方式：SDK采集</span>
            <span
              >合作方主体：广东天宸网络科技有限公司及将来受让运营vivo开放平台的公司</span
            >
            <span>合作方官网链接：</span
            ><a
              href="https://dev.vivo.com.cn/promote/pushNews"
              target="_blank"
              class="url"
              >https://dev.vivo.com.cn/promote/pushNews</a
            >
            <span>合作方隐私政策：</span
            ><a
              href="https://dev.vivo.com.cn/documentCenter/doc/366"
              target="_blank"
              class="url"
              >https://dev.vivo.com.cn/documentCenter/doc/366</a
            >
          </p>
          <p>
            <strong><span>华为 PUSH</span></strong>
            <span>使用目的：推送消息</span>
            <span>使用场景：在华为手机终端推送消息时使用</span>
            <span
              >涉及个人信息：应用基本信息、应用内设备标识符、设备的硬件信息、系统基本信息和系统设置信息</span
            >
            <span>收集方式：SDK采集</span>
            <span>合作方主体：华为软件技术有限公司</span>
            <span>合作方官网链接：</span
            ><a
              href="https://developer.huawei.com/consumer/cn/hms/huawei-pushkit"
              target="_blank"
              class="url"
              >https://developer.huawei.com/consumer/cn/hms/huawei-pushkit</a
            >
            <span>合作方隐私政策：</span
            ><a
              href="https://developer.huawei.com/consumer/cn/doc/HMSCore-Guides/sdk-data-security-0000001050042177"
              target="_blank"
              class="url"
              >https://developer.huawei.com/consumer/cn/doc/HMSCore-Guides/sdk-data-security-0000001050042177</a
            >
          </p>
          <p>
            <strong><span>OPPO PUSH</span></strong>
            <span>使用目的：推送消息</span>
            <span>使用场景：在OPPO手机终端推送消息时使用</span>
            <span
              >涉及个人信息：设备标识符（如IMEI、OAID、Serial、IMSI、Android
              ID、GAID）、应用信息、网络信息、IP地址、设备信息</span
            >
            <span>收集方式：SDK采集</span>
            <span>合作方主体：广东欢太科技有限公司</span>
            <span>合作方官网链接：</span
            ><a
              href="https://open.oppomobile.com/new/introduction?page_name=oppopush"
              target="_blank"
              class="url"
              >https://open.oppomobile.com/new/introduction?page_name=oppopush</a
            >
            <span>合作方隐私政策：</span
            ><a
              href="https://open.oppomobile.com/wiki/doc#id=10288"
              target="_blank"
              class="url"
              >https://open.oppomobile.com/wiki/doc#id=10288</a
            >
          </p>
          <!-- <p>
            <strong><span>魅族 PUSH</span></strong>
            <span>使用目的：推送消息</span>
            <span>使用场景：在魅族手机终端推送消息时使用</span>
            <span
              >涉及个人信息：设备标识符（如IMEI、Serial）、MAC地址、位置信息（如GPS）、WLAN接入点（如SSID、BSSID）</span
            >
            <span>收集方式：SDK采集</span>
            <span
              >合作方主体：珠海市魅族通讯设备有限公司及其关联方（简称魅族）、与魅族建立合作的合作方公司</span
            >
            <span>合作方官网链接：</span
            ><a href="https://open.flyme.cn/" target="_blank" class="url"
              >https://open.flyme.cn/</a
            >
            <span>合作方隐私政策：</span
            ><a
              href="https://www.meizu.com/legal.html"
              target="_blank"
              class="url"
              >https://www.meizu.com/legal.html</a
            >
          </p> -->
          <p>
            <strong><span>荣耀PUSH</span></strong>
            <span>用目的：推送消息</span>
            <span>使用场景：在魅族手机终端推送消息时使用</span>
            <span
              >涉及个人信息：设备标识符（如IMEI、Serial）、MAC地址、位置信息（如GPS）、WLAN接入点（如SSID、BSSID）</span
            >
            <span>收集方式：SDK采集</span>
            <span>合作方主体：荣耀终端有限公司</span>
            <span>合作方官网链接：</span
            ><a
              href="https://developer.hihonor.com/cn/promoteService"
              target="_blank"
              class="url"
              >https://developer.hihonor.com/cn/promoteService</a
            >
            <span>隐私权政策链接：</span
            ><a
              href="https://www.hihonor.com/cn/privacy/privacy-policy/"
              target="_blank"
              class="url"
              >https://www.hihonor.com/cn/privacy/privacy-policy/</a
            >
          </p>
          <!-- <p>
            <strong><span>Alipay（支付宝） SDK</span></strong>
            <span>使用目的：帮助用户在应用内使用支付宝付款</span>
            <span>使用场景：用户在应用内购买开通会员时，使用支付宝付款</span>
            <span
              >涉及个人信息：设备标识符（Android如IMEI、MEID、IMSI、Android
              ID、Serial、ICCID、OAID，iOS如IDFV、IDFA）、MAC地址、IP地址、WLAN接入点（如SSID、BSSID）、WiFi列表、蓝牙、设备传感器、网络信息、设备信息</span
            >
            <span>收集方式：SDK采集</span>
            <span>合作方主体：支付宝（杭州）信息技术有限公司</span>
            <span>合作方官网链接：</span
            ><a
              href="https://b.alipay.com/page/portal/home"
              target="_blank"
              class="url"
              >https://b.alipay.com/page/portal/home</a
            >
            <span>合作方隐私政策：</span
            ><a
              href="https://docs.open.alipay.com/54"
              target="_blank"
              class="url"
              >https://docs.open.alipay.com/54</a
            >
          </p> -->
          <p>
            <strong><span>微信支付 SDK</span></strong>
            <span>使用目的：帮助用户在应用内使用微信付款</span>
            <span>使用场景：用户在应用内购买商品时，使用微信支付付款</span>
            <span
              >涉及个人信息：设备标识符（Android如IMEI、Android
              ID、Serial）、MAC地址、WLAN接入点</span
            >
            <span>收集方式：SDK采集</span>
            <span>合作方主体：财富通支付科技有限公司</span>
            <span>合作方官网链接：</span
            ><a href="https://pay.weixin.qq.com/" target="_blank" class="url"
              >https://pay.weixin.qq.com/</a
            >
            <span>合作方隐私政策：</span
            ><a
              href="https://www.tenpay.com/v3/helpcenter/low/privacy.shtml"
              target="_blank"
              class="url"
              >https://www.tenpay.com/v3/helpcenter/low/privacy.shtml</a
            >
          </p>
          <p>
            <strong><span>微博SDK</span></strong>
            <span>使用目的：帮助用户分享内容至第三方应用</span>
            <span>使用场景：在用户使用微博登录或将内容分享至微博时使用</span>
            <span
              >涉及个人信息：设备标识符（Android如IMEI、Android
              ID、IMSI、Serial、ICCID、OAID，iOS如IDFV、IDFA）、MAC地址、
              WLAN接入点（如SSID、BSSID）、分享图片或内容、设备传感器信息</span
            >
            <span>收集方式：SDK采集</span>
            <span>合作方主体：北京微梦创科网络技术有限公司</span>
            <span>合作方官网链接：</span
            ><a
              href="https://open.weibo.com/wiki/SDK"
              target="_blank"
              class="url"
              >https://open.weibo.com/wiki/SDK</a
            >
            <span>合作方隐私政策：</span
            ><a
              href="https://weibo.com/signup/v5/privacy"
              target="_blank"
              class="url"
              >https://weibo.com/signup/v5/privacy</a
            >
          </p>
          <p>
            <strong><span>阿里云日志服务</span></strong>
            <span
              >使用目的：日志服务一站式提供数据采集、加工、查询与分析、可视化、告警、消费与投递功能，全面提升您在研发、运维、运营、安全场景的数字化能力。</span
            >
            <span>使用场景：APP初始化或APP运行时使用</span>
            <span
              >涉及个人信息：设备基础信息：设备制造商、设备品牌、设备型号、设备名称、设备操作系统信息、设备配置信息、设备环境信息；设备网络信息：网络运营商信息、网络类型、网络状态；设备应用信息：SDK宿主APP信息（包括：应用名称、应用版本）。</span
            >
            <span>收集方式：SDK采集</span>
            <span>合作方主体：阿里云计算有限公司</span>
            <span>合作方官网链接：</span
            ><a
              href="https://help.aliyun.com/document_detail/43200.html"
              target="_blank"
              class="url"
              >https://help.aliyun.com/document_detail/43200.html</a
            >
            <span>合作方隐私政策：</span
            ><a
              href="https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202112131013_69952.html?spm=a2c4g.11186623.0.0.2aa830e5NHOSMD"
              target="_blank"
              class="url"
              >https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202112131013_69952.html?spm=a2c4g.11186623.0.0.2aa830e5NHOSMD</a
            >
          </p>
          <!-- <p>
            <strong><span>兔小巢</span></strong>
            <span>使用目的：为App提供客户反馈互动社区</span>
            <span>使用场景：在提交反馈信息时</span>
            <span
              >涉及个人信息：微信用户头像、昵称、openid（可选）、QQ用户头像、昵称、openid（可选）、Android/iOS系统版本、网络类型（例如Wi-FI、3G、4G）、设备信息(手机型号、内核参数、SDCard信息、)、终端IP、联系方式（电话/邮箱/QQ/微信/其它信息）（可选）。</span
            >
            <span>收集方式：接口传输</span>
            <span>合作方主体： 腾讯公司</span>
            <span>合作方官网链接：</span
            ><a href="https://txc.qq.com/" target="_blank" class="url"
              >https://txc.qq.com/</a
            >
            <span>合作方隐私政策：</span
            ><a
              href="https://txc.qq.com/mobile/privacy.html"
              target="_blank"
              class="url"
              >https://txc.qq.com/mobile/privacy.html</a
            >
          </p> -->
          <p>
            <strong><span>AndroidUtilCode</span></strong>
            <span>使用目的：安卓开源的工具类库，帮助开发提高开发效率。</span>
            <span>使用场景：APP初始化、前台、APP运行时使用</span>
            <span
              >涉及个人信息：操作系统版本、磁盘使用情况、内存使用情况、IMEI、Andriodid、Mac地址、屏幕密度（每英寸点数）、CPU信息（频率、型号、架构）传感信息：加速传感信息；应用信息：应用程序包名；</span
            >
            <span>合作方官网链接：</span
            ><a
              href="https://github.com/Blankj/AndroidUtilCode"
              target="_blank"
              class="url"
              >https://github.com/Blankj/AndroidUtilCode</a
            >
          </p>
          <p>
            <strong><span>腾讯地图</span></strong>
            <span>使用目的： 为APP内首页提供定位服务</span>
            <span>使用场景： 用户根据定位地区展示相应商品使用</span>
            <span>涉及个人信息：位置信息</span>
            <span>收集方式：Api采集</span>
            <span>合作方主体：腾讯公司</span>
            <span>合作方官网链接： </span
            ><a href="https://lbs.qq.com/" target="_blank" class="url"
              >https://lbs.qq.com/</a
            >
            <span>合作方隐私政策： </span
            ><a
              href="https://privacy.qq.com/document/preview/29cd78d3d9da4da2b340bedb2d186612"
              target="_blank"
              class="url"
              >https://privacy.qq.com/document/preview/29cd78d3d9da4da2b340bedb2d186612</a
            >
          </p>
      
          
          <!-- <p>
            <strong><span>快手联盟 SDK</span></strong>
            <span>使用目的：广告投放及广告监测归因、反作弊</span>
            <span>使用场景：在用户触发广告相关场景时使用</span>
            <span
              >收集方式：初始化SDK后通过系统获取，初始化收集一次，设备信息永久缓存，用户信息不缓存</span
            >
            <span
              >涉及个人信息：【基础信息】：设备品牌、设备型号、软件系统版本、存储信息、运营商信息、设备时区、设备语言、网络信息等基础信息；idfa、idfv【位置信息】:
              IP 地址、MAC 地 址、GPS 位置信息、基站信 息、WIFI 信息</span
            >
            <span>合作方官网链接：</span
            ><a href="https://u.kuaishou.com/" target="_blank" class="url"
              >https://u.kuaishou.com/</a
            >
            <span>合作方隐私政策：</span
            ><a
              href="https://u.kuaishou.com/home/help/detail/1332/1338/1341"
              target="_blank"
              class="url"
              >https://u.kuaishou.com/home/help/detail/1332/1338/1341</a
            >
          </p> -->
          
          
          
        </div>
      </div>
    </div>
  
</template>

<script>
export default {
  name: "SDK",
  data() {
    return {};
  },
};
</script>

<style scoped type="text/css">
html {
  overflow-x: initial !important;
}
:root {
  --bg-color: #ffffff;
  --text-color: #333333;
  --select-text-bg-color: #b5d6fc;
  --select-text-font-color: auto;
  --monospace: "Lucida Console", Consolas, "Courier", monospace;
  --title-bar-height: 20px;
}
.mac-os-11 {
  --title-bar-height: 28px;
}
html {
  font-size: 14px;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}
body {
  margin: 0px;
  padding: 0px;
  height: auto;
  inset: 0px;
  font-size: 1rem;
  line-height: 1.428571;
  overflow-x: hidden;
  background: inherit;
  tab-size: 4;
}
iframe {
  margin: auto;
}
a.url {
  word-break: break-all;
}
a:active,
a:hover {
  outline: 0px;
}
.in-text-selection,
::selection {
  text-shadow: none;
  background: var(--select-text-bg-color);
  color: var(--select-text-font-color);
}
#write {
  margin: 0px auto;
  height: auto;
  width: inherit;
  word-break: normal;
  overflow-wrap: break-word;
  position: relative;
  white-space: normal;
  overflow-x: visible;
  padding-top: 36px;
}
#write.first-line-indent p {
  text-indent: 2em;
}
#write.first-line-indent li p,
#write.first-line-indent p * {
  text-indent: 0px;
}
#write.first-line-indent li {
  margin-left: 2em;
}
.for-image #write {
  padding-left: 8px;
  padding-right: 8px;
}
body.typora-export {
  padding-left: 30px;
  padding-right: 30px;
}
.typora-export .footnote-line,
.typora-export li,
.typora-export p {
  white-space: pre-wrap;
}
.typora-export .task-list-item input {
  pointer-events: none;
}
@media screen and (max-width: 500px) {
  body.typora-export {
    padding-left: 0px;
    padding-right: 0px;
  }
  #write {
    padding-left: 20px;
    padding-right: 20px;
  }
}
#write li > figure:last-child {
  margin-bottom: 0.5rem;
}
#write ol,
#write ul {
  position: relative;
}
img {
  max-width: 100%;
  vertical-align: middle;
  image-orientation: from-image;
}
button,
input,
select,
textarea {
  color: inherit;
  font-style: inherit;
  font-variant-caps: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  font-size-adjust: inherit;
  font-kerning: inherit;
  font-variant-alternates: inherit;
  font-variant-ligatures: inherit;
  font-variant-numeric: inherit;
  font-variant-east-asian: inherit;
  font-variant-position: inherit;
  font-variant-emoji: inherit;
  font-feature-settings: inherit;
  font-optical-sizing: inherit;
  font-variation-settings: inherit;
}
input[type="checkbox"],
input[type="radio"] {
  line-height: normal;
  padding: 0px;
}
*,
::after,
::before {
  box-sizing: border-box;
}
#write h1,
#write h2,
#write h3,
#write h4,
#write h5,
#write h6,
#write p,
#write pre {
  width: inherit;
}
#write h1,
#write h2,
#write h3,
#write h4,
#write h5,
#write h6,
#write p {
  position: relative;
}
p {
  line-height: inherit;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  break-after: avoid-page;
  break-inside: avoid;
  orphans: 4;
}
p {
  orphans: 4;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.8rem;
}
h3 {
  font-size: 1.6rem;
}
h4 {
  font-size: 1.4rem;
}
h5 {
  font-size: 1.2rem;
}
h6 {
  font-size: 1rem;
}
.md-math-block,
.md-rawblock,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.hidden {
  display: none;
}
.md-blockmeta {
  color: rgb(204, 204, 204);
  font-weight: 700;
  font-style: italic;
}
a {
  cursor: pointer;
}
sup.md-footnote {
  padding: 2px 4px;
  background-color: rgba(238, 238, 238, 0.7);
  color: rgb(85, 85, 85);
  border-radius: 4px;
  cursor: pointer;
}
sup.md-footnote a,
sup.md-footnote a:hover {
  color: inherit;
  text-transform: inherit;
  text-decoration: inherit;
}
#write input[type="checkbox"] {
  cursor: pointer;
  width: inherit;
  height: inherit;
}
figure {
  overflow-x: auto;
  margin: 1.2em 0px;
  max-width: calc(100% + 16px);
  padding: 0px;
}
figure > table {
  margin: 0px;
}
thead,
tr {
  break-inside: avoid;
  break-after: auto;
}
thead {
  display: table-header-group;
}
table {
  border-collapse: collapse;
  border-spacing: 0px;
  width: 100%;
  overflow: auto;
  break-inside: auto;
  text-align: left;
}
table.md-table td {
  min-width: 32px;
}
.CodeMirror-gutters {
  border-right-width: 0px;
  border-right-style: none;
  border-right-color: currentcolor;
  background-color: inherit;
}
.CodeMirror-linenumber {
}
.CodeMirror {
  text-align: left;
}
.CodeMirror-placeholder {
  opacity: 0.3;
}
.CodeMirror pre {
  padding: 0px 4px;
}
.CodeMirror-lines {
  padding: 0px;
}
div.hr:focus {
  cursor: none;
}
#write pre {
  white-space: pre-wrap;
}
#write.fences-no-line-wrapping pre {
  white-space: pre;
}
#write pre.ty-contain-cm {
  white-space: normal;
}
.CodeMirror-gutters {
  margin-right: 4px;
}
.md-fences {
  font-size: 0.9rem;
  display: block;
  break-inside: avoid;
  text-align: left;
  overflow: visible;
  white-space: pre;
  background: inherit;
  position: relative !important;
}
.md-fences-adv-panel {
  width: 100%;
  margin-top: 10px;
  text-align: center;
  padding-top: 0px;
  padding-bottom: 8px;
  overflow-x: auto;
}
#write .md-fences.mock-cm {
  white-space: pre-wrap;
}
.md-fences.md-fences-with-lineno {
  padding-left: 0px;
}
#write.fences-no-line-wrapping .md-fences.mock-cm {
  white-space: pre;
  overflow-x: auto;
}
.md-fences.mock-cm.md-fences-with-lineno {
  padding-left: 8px;
}
.CodeMirror-line,
twitterwidget {
  break-inside: avoid;
}
svg {
  break-inside: avoid;
}
.footnotes {
  opacity: 0.8;
  font-size: 0.9rem;
  margin-top: 1em;
  margin-bottom: 1em;
}
.footnotes + .footnotes {
  margin-top: 0px;
}
.md-reset {
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  vertical-align: top;
  background: 0px 0px;
  text-decoration: none;
  text-shadow: none;
  float: none;
  position: static;
  width: auto;
  height: auto;
  white-space: nowrap;
  cursor: inherit;
  line-height: normal;
  font-weight: 400;
  text-align: left;
  box-sizing: content-box;
  direction: ltr;
}
li div {
  padding-top: 0px;
}
blockquote {
  margin: 1rem 0px;
}
li .mathjax-block,
li p {
  margin: 0.5rem 0px;
}
li blockquote {
  margin: 1rem 0px;
}
li {
  margin: 0px;
  position: relative;
}
blockquote > :last-child {
  margin-bottom: 0px;
}
blockquote > :first-child,
li > :first-child {
  margin-top: 0px;
}
.footnotes-area {
  color: rgb(136, 136, 136);
  margin-top: 0.714rem;
  padding-bottom: 0.143rem;
  white-space: normal;
}
#write .footnote-line {
  white-space: pre-wrap;
}
@media print {
  body,
  html {
    border: 1px solid transparent;
    height: 99%;
    break-after: avoid;
    break-before: avoid;
    font-variant-ligatures: no-common-ligatures;
  }
  #write {
    margin-top: 0px;
    padding-top: 0px;
    border-color: transparent !important;
    padding-bottom: 0px !important;
  }
  .typora-export * {
    print-color-adjust: exact;
  }
  .typora-export #write {
    break-after: avoid;
  }
  .typora-export #write::after {
    height: 0px;
  }
  .is-mac table {
    break-inside: avoid;
  }
  .typora-export-show-outline .typora-export-sidebar {
    display: none;
  }
}
.footnote-line {
  margin-top: 0.714em;
  font-size: 0.7em;
}
a img,
img a {
  cursor: pointer;
}
pre.md-meta-block {
  font-size: 0.8rem;
  min-height: 0.8rem;
  white-space: pre-wrap;
  background: rgb(204, 204, 204);
  display: block;
  overflow-x: hidden;
}
p > .md-image:only-child:not(.md-img-error) img,
p > img:only-child {
  display: block;
  margin: auto;
}
#write.first-line-indent p > .md-image:only-child:not(.md-img-error) img {
  left: -2em;
  position: relative;
}
p > .md-image:only-child {
  display: inline-block;
  width: 100%;
}
#write .MathJax_Display {
  margin: 0.8em 0px 0px;
}
.md-math-block {
  width: 100%;
}
.md-math-block:not(:empty)::after {
  display: none;
}
.MathJax_ref {
  fill: currentcolor;
}
[contenteditable="true"]:active,
[contenteditable="true"]:focus,
[contenteditable="false"]:active,
[contenteditable="false"]:focus {
  outline: 0px;
  box-shadow: none;
}
.md-task-list-item {
  position: relative;
  list-style-type: none;
}
.task-list-item.md-task-list-item {
  padding-left: 0px;
}
.md-task-list-item > input {
  position: absolute;
  top: 0px;
  left: 0px;
  margin-left: -1.2em;
  margin-top: calc(1em - 10px);
  border: medium;
}
.math {
  font-size: 1rem;
}
.md-toc {
  min-height: 3.58rem;
  position: relative;
  font-size: 0.9rem;
  border-radius: 10px;
}
.md-toc-content {
  position: relative;
  margin-left: 0px;
}
.md-toc-content::after,
.md-toc::after {
  display: none;
}
.md-toc-item {
  display: block;
  color: rgb(65, 131, 196);
}
.md-toc-item a {
  text-decoration: none;
}
.md-toc-inner:hover {
  text-decoration: underline;
}
.md-toc-inner {
  display: inline-block;
  cursor: pointer;
}
.md-toc-h1 .md-toc-inner {
  margin-left: 0px;
  font-weight: 700;
}
.md-toc-h2 .md-toc-inner {
  margin-left: 2em;
}
.md-toc-h3 .md-toc-inner {
  margin-left: 4em;
}
.md-toc-h4 .md-toc-inner {
  margin-left: 6em;
}
.md-toc-h5 .md-toc-inner {
  margin-left: 8em;
}
.md-toc-h6 .md-toc-inner {
  margin-left: 10em;
}
@media screen and (max-width: 48em) {
  .md-toc-h3 .md-toc-inner {
    margin-left: 3.5em;
  }
  .md-toc-h4 .md-toc-inner {
    margin-left: 5em;
  }
  .md-toc-h5 .md-toc-inner {
    margin-left: 6.5em;
  }
  .md-toc-h6 .md-toc-inner {
    margin-left: 8em;
  }
}
a.md-toc-inner {
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
}
.footnote-line a:not(.reversefootnote) {
  color: inherit;
}
.reversefootnote {
  font-family: ui-monospace, sans-serif;
}
.md-attr {
  display: none;
}
.md-fn-count::after {
  content: ".";
}
code,
pre,
samp,
tt {
  font-family: var(--monospace);
}
kbd {
  margin: 0px 0.1em;
  padding: 0.1em 0.6em;
  font-size: 0.8em;
  color: rgb(36, 39, 41);
  background: rgb(255, 255, 255);
  border: 1px solid rgb(173, 179, 185);
  border-radius: 3px;
  box-shadow: rgba(12, 13, 14, 0.2) 0px 1px 0px,
    rgb(255, 255, 255) 0px 0px 0px 2px inset;
  white-space: nowrap;
  vertical-align: middle;
}
.md-comment {
  color: rgb(162, 127, 3);
  opacity: 0.6;
  font-family: var(--monospace);
}
code {
  text-align: left;
  vertical-align: initial;
}
a.md-print-anchor {
  white-space: pre !important;
  border-width: medium !important;
  border-style: none !important;
  border-color: currentcolor !important;
  display: inline-block !important;
  position: absolute !important;
  width: 1px !important;
  right: 0px !important;
  outline: 0px !important;
  background: 0px 0px !important;
  text-decoration: initial !important;
  text-shadow: initial !important;
}
.os-windows.monocolor-emoji .md-emoji {
  font-family: "Segoe UI Symbol", sans-serif;
}
.md-diagram-panel > svg {
  max-width: 100%;
}
[lang="flow"] svg,
[lang="mermaid"] svg {
  max-width: 100%;
  height: auto;
}
[lang="mermaid"] .node text {
  font-size: 1rem;
}
table tr th {
  border-bottom-width: 0px;
  border-bottom-style: none;
  border-bottom-color: currentcolor;
}
video {
  max-width: 100%;
  display: block;
  margin: 0px auto;
}
iframe {
  max-width: 100%;
  width: 100%;
  border: medium;
}
.highlight td,
.highlight tr {
  border: 0px;
}
mark {
  background: rgb(255, 255, 0);
  color: rgb(0, 0, 0);
}
.md-html-inline .md-plain,
.md-html-inline strong,
mark .md-inline-math,
mark strong {
  color: inherit;
}
.md-expand mark .md-meta {
  opacity: 0.3 !important;
}
mark .md-meta {
  color: rgb(0, 0, 0);
}
@media print {
  .typora-export h1,
  .typora-export h2,
  .typora-export h3,
  .typora-export h4,
  .typora-export h5,
  .typora-export h6 {
    break-inside: avoid;
  }
}
.md-diagram-panel .messageText {
  stroke: none !important;
}
.md-diagram-panel .start-state {
  fill: var(--node-fill);
}
.md-diagram-panel .edgeLabel rect {
  opacity: 1 !important;
}
.md-fences.md-fences-math {
  font-size: 1em;
}
.md-fences-advanced:not(.md-focus) {
  padding: 0px;
  white-space: nowrap;
  border: 0px;
}
.md-fences-advanced:not(.md-focus) {
  background: inherit;
}
.typora-export-show-outline .typora-export-content {
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: row;
}
.typora-export-sidebar {
  width: 300px;
  font-size: 0.8rem;
  margin-top: 80px;
  margin-right: 18px;
}
.typora-export-show-outline #write {
  --webkit-flex: 2;
  flex: 2 1 0%;
}
.typora-export-sidebar .outline-content {
  position: fixed;
  top: 0px;
  max-height: 100%;
  overflow: hidden auto;
  padding-bottom: 30px;
  padding-top: 60px;
  width: 300px;
}
@media screen and (max-width: 1024px) {
  .typora-export-sidebar,
  .typora-export-sidebar .outline-content {
    width: 240px;
  }
}
@media screen and (max-width: 800px) {
  .typora-export-sidebar {
    display: none;
  }
}
.outline-content li,
.outline-content ul {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  list-style: none;
}
.outline-content ul {
  margin-top: 0px;
  margin-bottom: 0px;
}
.outline-content strong {
  font-weight: 400;
}
.outline-expander {
  width: 1rem;
  height: 1.428571rem;
  position: relative;
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
  padding-left: 4px;
}
.outline-expander::before {
  content: "";
  position: relative;
  font-family: Ionicons;
  display: inline-block;
  font-size: 8px;
  vertical-align: middle;
}
.outline-item {
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;
}
.outline-expander:hover::before {
  content: "";
}
.outline-h1 > .outline-item {
  padding-left: 0px;
}
.outline-h2 > .outline-item {
  padding-left: 1em;
}
.outline-h3 > .outline-item {
  padding-left: 2em;
}
.outline-h4 > .outline-item {
  padding-left: 3em;
}
.outline-h5 > .outline-item {
  padding-left: 4em;
}
.outline-h6 > .outline-item {
  padding-left: 5em;
}
.outline-label {
  cursor: pointer;
  display: table-cell;
  vertical-align: middle;
  text-decoration: none;
  color: inherit;
}
.outline-label:hover {
  text-decoration: underline;
}
.outline-item:hover {
  border-color: rgb(245, 245, 245);
  background-color: var(--item-hover-bg-color);
}
.outline-item:hover {
  margin-left: -28px;
  margin-right: -28px;
  border-left-width: 28px;
  border-left-style: solid;
  border-left-color: transparent;
  border-right-width: 28px;
  border-right-style: solid;
  border-right-color: transparent;
}
.outline-item-single .outline-expander::before,
.outline-item-single .outline-expander:hover::before {
  display: none;
}
.outline-item-open > .outline-item > .outline-expander::before {
  content: "";
}
.outline-children {
  display: none;
}
.info-panel-tab-wrapper {
  display: none;
}
.outline-item-open > .outline-children {
  display: block;
}
.typora-export .outline-item {
  padding-top: 1px;
  padding-bottom: 1px;
}
.typora-export .outline-item:hover {
  margin-right: -8px;
  border-right-width: 8px;
  border-right-style: solid;
  border-right-color: transparent;
}
.typora-export .outline-expander::before {
  content: "+";
  font-family: inherit;
  top: -1px;
}
.typora-export .outline-expander:hover::before,
.typora-export .outline-item-open > .outline-item > .outline-expander::before {
  content: "−";
}
.typora-export-collapse-outline .outline-children {
  display: none;
}
.typora-export-collapse-outline .outline-item-open > .outline-children,
.typora-export-no-collapse-outline .outline-children {
  display: block;
}
.typora-export-no-collapse-outline .outline-expander::before {
  content: "" !important;
}
.typora-export-show-outline
  .outline-item-active
  > .outline-item
  .outline-label {
  font-weight: 700;
}
.md-inline-math-container mjx-container {
  zoom: 0.95;
}
mjx-container {
  break-inside: avoid;
}

:root {
  --side-bar-bg-color: #fafafa;
  --control-text-color: #777;
}

@include-when-export url(https://fonts.loli.net/css?family=Open+Sans:400italic,700italic,700,400&subset=latin,latin-ext);

/* open-sans-regular - latin-ext_latin */
/* open-sans-italic - latin-ext_latin */
/* open-sans-700 - latin-ext_latin */
/* open-sans-700italic - latin-ext_latin */
html {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: "Open Sans", "Clear Sans", "Helvetica Neue", Helvetica, Arial,
    "Segoe UI Emoji", sans-serif;
  color: rgb(51, 51, 51);
  line-height: 1.6;
}

#write {
  max-width: 860px;
  margin: 0 auto;
  padding: 30px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 1400px) {
  #write {
    max-width: 1024px;
  }
}

@media only screen and (min-width: 1800px) {
  #write {
    max-width: 1200px;
  }
}

#write > ul:first-child,
#write > ol:first-child {
  margin-top: 30px;
}

a {
  color: #4183c4;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
  line-height: 1.4;
  cursor: text;
}
h1:hover a.anchor,
h2:hover a.anchor,
h3:hover a.anchor,
h4:hover a.anchor,
h5:hover a.anchor,
h6:hover a.anchor {
  text-decoration: none;
}
h1 tt,
h1 code {
  font-size: inherit;
}
h2 tt,
h2 code {
  font-size: inherit;
}
h3 tt,
h3 code {
  font-size: inherit;
}
h4 tt,
h4 code {
  font-size: inherit;
}
h5 tt,
h5 code {
  font-size: inherit;
}
h6 tt,
h6 code {
  font-size: inherit;
}
h1 {
  font-size: 2.25em;
  line-height: 1.2;
  border-bottom: 1px solid #eee;
}
h2 {
  font-size: 1.75em;
  line-height: 1.225;
  border-bottom: 1px solid #eee;
}

/*@media print {
      .typora-export h1,
      .typora-export h2 {
          border-bottom: none;
          padding-bottom: initial;
      }
  
      .typora-export h1::after,
      .typora-export h2::after {
          content: "";
          display: block;
          height: 100px;
          margin-top: -96px;
          border-top: 1px solid #eee;
      }
  }*/

h3 {
  font-size: 1.5em;
  line-height: 1.43;
}
h4 {
  font-size: 1.25em;
}
h5 {
  font-size: 1em;
}
h6 {
  font-size: 1em;
  color: #777;
}
p,
blockquote,
ul,
ol,
dl,
table {
  margin: 0.8em 0;
}
li > ol,
li > ul {
  margin: 0 0;
}
hr {
  height: 2px;
  padding: 0;
  margin: 16px 0;
  background-color: #e7e7e7;
  border: 0 none;
  overflow: hidden;
  box-sizing: content-box;
}

li p.first {
  display: inline-block;
}
ul,
ol {
  padding-left: 30px;
}
ul:first-child,
ol:first-child {
  margin-top: 0;
}
ul:last-child,
ol:last-child {
  margin-bottom: 0;
}
blockquote {
  border-left: 4px solid #dfe2e5;
  padding: 0 15px;
  color: #777777;
}
blockquote blockquote {
  padding-right: 0;
}
table {
  padding: 0;
  word-break: initial;
}
table tr {
  border: 1px solid #dfe2e5;
  margin: 0;
  padding: 0;
}
table tr:nth-child(2n),
thead {
  background-color: #f8f8f8;
}
table th {
  font-weight: bold;
  border: 1px solid #dfe2e5;
  border-bottom: 0;
  margin: 0;
  padding: 6px 13px;
}
table td {
  border: 1px solid #dfe2e5;
  margin: 0;
  padding: 6px 13px;
}
table th:first-child,
table td:first-child {
  margin-top: 0;
}
table th:last-child,
table td:last-child {
  margin-bottom: 0;
}

.CodeMirror-lines {
  padding-left: 4px;
}

.code-tooltip {
  box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.3);
  border-top: 1px solid #eef2f2;
}

.md-fences,
code,
tt {
  border: 1px solid #e7eaed;
  background-color: #f8f8f8;
  border-radius: 3px;
  padding: 0;
  padding: 2px 4px 0px 4px;
  font-size: 0.9em;
}

code {
  background-color: #f3f4f4;
  padding: 0 2px 0 2px;
}

.md-fences {
  margin-bottom: 15px;
  margin-top: 15px;
  padding-top: 8px;
  padding-bottom: 6px;
}

.md-task-list-item > input {
  margin-left: -1.3em;
}

@media print {
  html {
    font-size: 13px;
  }
  pre {
    page-break-inside: avoid;
    word-wrap: break-word;
  }
}

.md-fences {
  background-color: #f8f8f8;
}
#write pre.md-meta-block {
  padding: 1rem;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f7f7f7;
  border: 0;
  border-radius: 3px;
  color: #777777;
  margin-top: 0 !important;
}

.mathjax-block > .code-tooltip {
  bottom: 0.375rem;
}

.md-mathjax-midline {
  background: #fafafa;
}

#write > h3.md-focus:before {
  left: -1.5625rem;
  top: 0.375rem;
}
#write > h4.md-focus:before {
  left: -1.5625rem;
  top: 0.285714286rem;
}
#write > h5.md-focus:before {
  left: -1.5625rem;
  top: 0.285714286rem;
}
#write > h6.md-focus:before {
  left: -1.5625rem;
  top: 0.285714286rem;
}
.md-image > .md-meta {
  /*border: 1px solid #ddd;*/
  border-radius: 3px;
  padding: 2px 0px 0px 4px;
  font-size: 0.9em;
  color: inherit;
}

.md-tag {
  color: #a7a7a7;
  opacity: 1;
}

.md-toc {
  margin-top: 20px;
  padding-bottom: 20px;
}

.sidebar-tabs {
  border-bottom: none;
}

#typora-quick-open {
  border: 1px solid #ddd;
  background-color: #f8f8f8;
}

#typora-quick-open-item {
  background-color: #fafafa;
  border-color: #fefefe #e5e5e5 #e5e5e5 #eee;
  border-style: solid;
  border-width: 1px;
}

/** focus mode */
.on-focus-mode blockquote {
  border-left-color: rgba(85, 85, 85, 0.12);
}

header,
.context-menu,
.megamenu-content,
footer {
  font-family: "Segoe UI", "Arial", sans-serif;
}

.file-node-content:hover .file-node-icon,
.file-node-content:hover .file-node-open-state {
  visibility: visible;
}

.mac-seamless-mode #typora-sidebar {
  background-color: #fafafa;
  background-color: var(--side-bar-bg-color);
}

.md-lang {
  color: #b4654d;
}

/*.html-for-mac {
      --item-hover-bg-color: #E6F0FE;
  }*/

#md-notification .btn {
  border: 0;
}

.dropdown-menu .divider {
  border-color: #e5e5e5;
  opacity: 0.4;
}

.ty-preferences .window-content {
  background-color: #fafafa;
}

.ty-preferences .nav-group-item.active {
  color: white;
  background: #999;
}

.menu-item-container a.menu-style-btn {
  background-color: #f5f8fa;
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.8),
    hsla(0, 0%, 100%, 0)
  );
}

@media print {
  @page {
    margin: 0 0 0 0;
  }
  body.typora-export {
    padding-left: 0;
    padding-right: 0;
  }
  #write {
    padding: 0;
  }
}
</style>
