import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/HomeView.vue';
import AboutUs from '@/views/AboutView.vue';
import PropertyInfo from '@/views/PropertyView.vue';

import Cooperation from "@/views/CooperationView.vue";
import Contact from "@/views/ContactView.vue";
import Download from "@/views/DownloadView.vue";
import agreement from "@/views/AgreementView.vue";

import privacy from "@/views/PrivacyAgreementAndroid.vue";
import user from "@/views/UserAgreement.vue";
import permissions from "@/views/PermissionsAndroid.vue";
import sdk from "@/views/SDK.vue";


const routes = [
    {
        path: '/',
        name: 'HomeView',
        component: Home,
        meta: { keepAlive: false } // 设置为false表示不缓存
    },
    {
        path: '/about/:activeTab?',
        // path: '/about',
        name: 'AboutView',
        component: AboutUs,
        props: true,
        // props: route => ({ activeTab: route.params.activeTab })
        meta: { keepAlive: false }

    },
    {
        path: '/property/:index?',
        // path: '/property',
        name: 'PropertyView',
        component: PropertyInfo,
        props: true,
        meta: { keepAlive: false }

    },
    {
        path: '/cooper',
        name: 'CooperationView',
        component: Cooperation,
        meta: { keepAlive: false }
    },
    {
        path: '/contact',
        name: 'ContactView',
        component: Contact,
        meta: { keepAlive: false }
    },
    {
        path: '/download',
        name: 'DownloadView',
        component: Download,
        meta: { keepAlive: false , hiddenNav: true}
    },
    {
        path: '/agreement',
        name: 'AgreementView',
        component: agreement,
        meta: { keepAlive: false , hiddenNav: true}
    },
    
    {
        path: '/privacy',
        name: 'PrivacyAgreementAndroid',
        component: privacy,
        meta: { keepAlive: false , hiddenNav: true}
    },
    {
        path: '/user',
        name: 'UserAgreement',
        component: user,
        meta: { keepAlive: false , hiddenNav: true}
    },
    {
        path: '/permissions',
        name: 'PermissionsAndroid',
        component: permissions,
        meta: { keepAlive: false , hiddenNav: true}
    },
    {
        path: '/sdk',
        name: 'SDK',
        component: sdk,
        meta: { keepAlive: false , hiddenNav: true}
    },
];

const router = createRouter({
    mode: 'history',
   
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
