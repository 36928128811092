<template>
  <div
    style="
      padding: 30px;
      text-indent: 2rem;
      margin: 0 auto;
      word-wrap: break-word; /* 支持长单词或URL的换行 */
      overflow-wrap: break-word; /* 为了兼容性 */
    "
  >
    <div v-if="!user">
      <p>24小时买卖网平台隐私政策</p>
      <p>&nbsp;</p>

      <p><b>更新日期：2024年08月7日</b></p>
      <p><b>生效日期：2024年09月12日</b></p>

      <p>
        二十四小时买卖网（大连）科技有限公司及其关联方（简称&ldquo;我们&rdquo;）作为24小时买卖网平台的运营者，深知个人信息对您的重要性，我们将按照法律法规的规定，保护您的个人信息及隐私安全。本隐私政策详细描述了我们如何收集、使用和处理与您有关的个人信息。特别提示：希望您在使用24小时买卖网平台及相关服务前仔细阅读并理解本隐私政策，做出适当的选择。使用24小时买卖网平台以您接受本政策为前提条件。
      </p>
      <p>本隐私政策将帮助您了解：</p>
      <p>
        <b
          >1.我们会遵循该隐私政策收集、使用您的信息，但不会仅因您同意本隐私政策而采用强制捆绑的方式收集个人信息。</b
        >
      </p>
      <p>
        2.当您使用或开启相关功能或使用服务时，为实现功能、服务所必需，我们会收集、使用相关信息。除了为实现基本业务功能或根据法律法规要求所必需的必要信息，您均可以拒绝提供且不影响其他功能或服务。我们将在隐私政策中逐项说明哪些是必要信息。
      </p>
      <p>
        3.如果您未登录帐号，我们会通过设备对应的标识符信息来保障信息推送的基本功能。如果您登录了帐号，我们会根据帐号信息实现信息推送。
      </p>
      <p>
        4.为实现业务功能或根据法律法规要求所必需，我们会在您使用特定功能或服务时收集、使用您的敏感个人信息。
      </p>
      <p>
        <b
          >5.精确地理位置、摄像头（相机）、麦克风、相册（存储）、日历、活动记录权限</b
        >，均不会默认开启，只有经过您的明示授权才会在为实现特定功能或服务时使用。特别需要指出的是，即使经过您的授权，我们获得了这些敏感权限，也不会在相关功能或服务不需要时而收集您的信息。具体权限申请与使用情况请见：
      </p>
      <p>应用程序应用权限申请与使用情况说明</p>
      <p>
        6.本隐私政策适用于24小时买卖网平台（包含24小时买卖网平台官方网站、24小时买卖网平台应用程序）向您提供的产品和服务。如您使用我们或关联公司向您提供的某项或某几项服务，有其单独的隐私政策的，则相应服务或产品适用相应隐私政策；如未设立独立隐私政策的，则本政策同样适用于该部分产品或服务。
      </p>
      <p>
        7.下文将帮您详细了解我们如何收集、使用，存储，转移（如适用）与保护个人信息，帮您了解查阅，复制，删除，更正，撤回授权个人信息。
      </p>
      <p>目录：</p>
      <p>一、我们如何收集和使用个人信息</p>
      <p>二、我们对 Cookie 和同类技术的使用</p>
      <p>三、我们如何存储个人信息</p>
      <p>四、数据使用过程中涉及的合作方以及转移、公开个人信息</p>
      <p>五、我们如何保护个人信息的安全</p>
      <p>六、管理您的个人信息</p>
      <p>七、未成年人使用条款</p>
      <p>八、隐私政策的修订和通知</p>
      <p>九、联系我们</p>
      <b
        ><p>一、我们如何收集和使用个人信息</p>
        <p>
          在您使用&ldquo;24小时买卖网平台&rdquo;及服务的过程中，我们将根据合法、正当、必要的原则，收集信息。我们收集或您提供的信息将用于：
        </p>
        <p>1.保障产品的正常基础运行</p>
        <p>2.实现各项功能和服务</p>
        <p>3.优化、改善产品和服务</p>
        <p>4.保障产品、服务以及用户使用安全</p>
        <p>5.遵循法律法规与国家标准的规定</p>
      </b>
      <p>（一）我们直接收集与使用的个人信息</p>
      <p>
        我们会按照如下方式收集您在使用服务时主动提供的，以及通过自动化手段收集您在使用功能或接受服务过程中产生的信息：
      </p>
      <ol>
        <li>运营与安全运行/保障</li>
      </ol>
      <p>1.1保障&ldquo;24小时买卖网平台&rdquo;和相关服务的正常运行</p>
      <p>
        当您使用&ldquo;24小时买卖网平台&rdquo;及相关服务时，为了保障软件与服务的正常运行，我们会收集您的IP
        地址、操作日志等信息；当您使用24小时买卖网平台应用程序时，我们会收集您的<b>硬件型号、硬件序列号、操作系统版本号、国际移动设备识别码（IMEI）、网络设备硬件地址（MAC）、IP
        地址、软件版本号、网络接入方式及类型、操作日志、设备传感器数据</b>等信息。请您了解，这些信息是我们提供服务和保障产品正常运行所必须收集的基本信息。
      </p>
      <p>1.2保障产品、服务及用户使用安全</p>
      <p>
        为帮助我们更好地了解&ldquo;24小时买卖网平台&rdquo;及相关服务的运行情况，以便确保运行与提供服务的安全，我们可能记录<b>网络日志信息，以及使用软件及相关服务的频率、崩溃数据、总体安装、使用情况、性能数据等信息。1.3我们的产品集成友盟+SDK，友盟+SDK需要收集您的设备Mac地址、唯一设备识别码（IMEI/android
        ID/IDFA/OPENUDID/IP地址/GUID、SIM 卡 IMSI
        信息）以提供统计分析服务，并通过地理位置校准报表数据准确性，提供基础反作弊能力。</b>
      </p>
      
      <b><p>2.注册、登录、认证、找回&ldquo;24小时买卖网平台&rdquo;和相关服务</p>
      <p>2.1注册、登录</p>
      <p>
        （1）我们通过&ldquo;24小时买卖网平台&rdquo;为您提供服务，您需要提供联系邮箱、联系电话等。并且完善相关的网络身份识别信息（头像、账户名称、密码），收集这些信息是为了帮助您完成注册。
      </p>
      <p>
        （2）当您注册、登录&ldquo;24小时买卖网平台&rdquo;及相关服务时，您可以通过手机号创建账号，我们将通过发送短信验证码来验证您的身份是否有效，并且您可以完善相关的网络身份识别信息（如密码），收集这些信息是为了帮助您完成注册。如果拒绝提供，您将可能无法获得登录状态下的相关服务，但不影响其他功能的正常使用。
      </p>
      <p>
        （3）基于我们与通信运营商的合作，当您使用【24小时买卖网平台】&ldquo;一键登录&rdquo;功能时，经过您的明示同意，运营商会将您的手机号码发送给我们，便于我们为您提供快捷的登录服务。如果拒绝提供手机号将无法使用&ldquo;一键登录&rdquo;方式注册登录【24小时买卖网平台】，但不影响您通过其他方式注册登录，也不影响其他功能的正常使用。
      </p>
      <p>
        （4）您可以使用第三方帐号登录并使用【24小时买卖网平台】，经过您同意，我们将获取您在第三方平台注册的公开信息（头像、昵称以及您授权的其他信息），用于与【24小时买卖网平台】帐号绑定，使您可以直接登录并使用本产品和相关服务。在使用第三方应用进行授权登录时，第三方应用可能需要将实现登录所必需的信息（登录口令等）在剪切板中写入与读取，以实现应用间的帐号授权。这些信息仅供实现登录相关的目的所使用，不会收集您的隐私信息。
      </p>
      <p>2.2认证</p>
      <p>
        在您使用身份认证的功能或相关服务所需时，根据相关法律法规，我们可能会收集您的真实身份信息（真实姓名、身份证号码、电话号码等）以完成实名验证。
        部分信息属于个人敏感信息，您可以拒绝提供，如果拒绝提供您将可能无法获得相关服务，但不影响其他功能与服务的正常使用。
      </p>
      <p>2.3.帐号找回</p>
      <p>
        您可以对您的帐号进行找回，为保障您帐号的安全所必需，在找回帐号时我们可能需要您的相关信息以进行验证，必要时可能通过实名验证、人脸识别等方式验证您的身份。
      </p>
      <p>3.我们在您使用产品或服务过程中收集的信息</p>
      <p>
        当您与我们联系时，我们可能会保存您的通信/通话记录和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。
      </p>
      <p>4.开展营销活动</p>
      <p>
        当您选择参加我们举办的有关营销活动时，根据活动需要您提供姓名、通信地址、联系方式信息。这些信息可能包括个人敏感信息（如个人电话号码、银行账号等）。
      </p>
      <p>5.进行人脸认证</p>
      <p>
        当您使用24小时买卖网平台应用程序，为进行登录，我们会申请调用您的终端摄像头权限。
      </p>
      <p>6.上传/下载/保存创意的图片或视频</p>
      <p>
        为了能够读取/上传/下载/保存创意的图片或视频，我们会申请调用您的终端存储权限。
      </p>
      <p>8.获知地址或出于账号安全风控目的</p>
      <p>
        地理位置权限，包含通过全球定位系统（GPS）或网络位置信息（例如基站和WLAN）等传感器信息获取精准地理位置信息，及通过网络位置信息（例如基站和WLAN）获取大致地理位置信息。
      </p>
      <p>9.反馈问题</p>
      <p>
        当您通过24小时买卖网平台应用程序反馈问题时，我们需要访问您的剪切板，读取uid以快速定位并排查问题。
      </p>
      <p>10.分享</p>
      <p>
        在您使用24小时买卖网平台应用程序分享或接收被分享的信息情形下，我们需要访问您的剪切板，读取其中包含的口令以实现跳转。
      </p>
      <p>（二）我们通过间接获得方式收集到的您的个人信息</p>
      <p>
        为了给您提供更好、更优的服务，或共同为您提供服务，或为了预防互联网欺诈的目的，我们的关联公司、合作伙伴会依据法律规定或与您的约定或征得您同意前提下，向我们分享您的个人信息。
      </p>
      <p>（三）其他用途</p>
      <p>
        我们可能会对收集的信息进行去标识化地研究、统计分析和预测，用于改善和提升&ldquo;24小时买卖网平台&rdquo;的服务，为用户营销决策提供产品或技术服务支撑。
      </p>
      <p>
        我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。
      </p>
      <p>（四） 收集、使用个人信息目的变更</p>
      <p>
        请您了解，随着我们业务的发展，可能会对【24小时买卖网平台】的功能和提供的服务有所调整变化。原则上，当新功能或服务与我们当前提供的功能或服务相关时，收集与使用的个人信息将与原处理目的具有直接或合理关联。在与原处理目的无直接或合理关联的场景下，我们收集、使用您的个人信息，会再次按照法律法规及国家标准的要求以页面提示、交互流程、协议确认方式另行向您进行告知说明，并征得您的同意。
      </p>
    </b>
      <p>（五）依法豁免征得同意收集和使用的个人信息</p>
      <p>
        请您理解，在下列情形中，根据法律法规及相关国家标准，我们收集和使用您的个人信息无需征得您的授权同意：
      </p>
      <p>（1）与我们履行法律法规规定的义务相关的；</p>
      <p>（2）与国家安全、国防安全直接相关的；</p>
      <p>（3）与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p>（4）与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
      <p>
        （5）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
      </p>
      <p>（6）所收集的您的个人信息是您自行向社会公众公开的；</p>
      <p>
        （7）从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；
      </p>
      <p>（8）根据您的要求签订或履行合同所必需的；</p>
      <p>
        （9）用于维护&ldquo;24小时买卖网平台&rdquo;软件及相关服务的安全稳定运行所必需的，例如发现、处置&ldquo;24小时买卖网平台&rdquo;软件及相关服务的故障；
      </p>
      <p>（10）为合法的新闻报道所必需的；</p>
      <p>
        （11）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
      </p>
      <p>（12）法律法规规定的其他情形。</p>
      <p>
        特别提示您注意，如信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上您的个人信息；当您的信息可以单独或结合其他信息识别到您的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。
      </p>
      <p>二、我们对 Cookie 和同类技术的使用</p>
      <p>
        Cookie
        和同类技术是互联网中的通用常用技术。当您使用&ldquo;24小时买卖网平台&rdquo;及相关服务时，我们可能会使用相关技术向您的设备发送一个或多个
        Cookie 或匿名标识符，以收集和存储您访问、使用本产品时的信息。我们使用
        Cookie 和同类技术主要为了实现以下功能或服务：
      </p>
      <p>（一）保障产品与服务的安全、高效运转</p>
      <p>
        我们可能会设置认证与保障安全性的 Cookie
        或匿名标识符，使我们确认您是否安全登录服务，或者是否遇到盗用、欺诈等不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。
      </p>
      <p>（二）帮助您获得更轻松的访问体验</p>
      <p>
        使用此类技术可以帮助您省去重复您填写个人信息、输入搜索内容的步骤和流程（例如：记录搜索历史）。
      </p>
      <p>（三）cookie的清除</p>
      <p>
        大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以在浏览器设置功能中进行相应的数据清除操作。如您进行清除，可能因为这些修改，您可能无法使用依赖于Cookie由公司提供的服务或相应功能。
      </p>
      <p>三、我们如何存储个人信息</p>
      <p>（一）存储的地点</p>
      <p>
        我们依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，我们将会遵循相关国家规定或者征求您的同意。
      </p>
      <p>（二）存储期限</p>
      <p>
        我们仅在为提供&ldquo;24小时买卖网平台&rdquo;及服务之目的所必需的期间内保留您的个人信息。例如：当您使用手机号码注册登录时，我们需要持续保留您的手机号码，以便于向您提供正常的服务、应对您可能的查询和客诉并保障您的帐号和系统安全。
        如果您注销帐户、主动删除个人信息或超出必要期限后，我们将对您的个人信息进行删除或匿名化处理，但以下情况除外：
      </p>
      <p>
        （1）遵从法律法规有关信息留存的要求（例如：《电子商务法》规定：商品和服务信息、交易信息保存时间自交易完成之日起不少于三年）。
      </p>
      <p>（2）出于财务、审计、争议解决等目的需要合理延长期限的。</p>
      <p>四、数据使用过程中涉及的合作方以及转移、公开个人信息</p>
      <p>（一）数据使用过程中涉及的合作方</p>
      <p>1.原则</p>
      <p>
        1.1合法原则：与合作方合作过程中涉及数据使用活动的，必须具有合法目的、符合法定的合法性基础。如果合作方使用信息不再符合合法原则，则其不应再使用您的个人信息，或在获得相应合法性基础后再行使用。
      </p>
      <p>
        1.2.正当与最小必要原则：数据使用必须具有正当目的，且应以达成目的必要为限。
      </p>
      <p>
        1.3.安全审慎原则：&nbsp;我们将审慎评估合作方使用数据的目的，对这些合作方的安全保障能力进行综合评估，并要求其遵循合作法律协议。我们会对合作方获取信息的软件工具开发包（SDK）、应用程序接口（API）进行严格的安全监测，以保护数据安全。
      </p>
      <p>接入合作方目录</p>
      <ol start="2">
        <li>委托处理</li>
      </ol>
      <p>
        对于委托处理个人信息的场景，我们会与受托合作方根据法律规定签署相关处理协议，并对其个人信息使用活动进行监督。
      </p>
      <ol start="3">
        <li>共同处理</li>
      </ol>
      <p>
        对于共同处理个人信息的场景，我们会与合作方根据法律规定签署相关协议并约定各自的权利和义务，确保在使用相关个人信息的过程中遵守法律的相关规定、保护数据安全。
      </p>
      <p>4.合作方的范围</p>
      <p>
        若具体功能和场景中涉及由我们的关联方、第三方提供服务，则合作方范围包括我们的关联方与第三方。
      </p>
      <ol start="5">
        <li>实现功能或服务的数据使用</li>
      </ol>
      <p>
        5.1在您使用身份认证的功能或相关服务所需时，根据相关法律法规、安全保障要求可能需要完成实名认证以验证您的身份。在实名认证的过程中，与我们合作的认证服务机构需要使用您的真实姓名、身份证号码、手机号码及人脸信息。部分信息是个人敏感信息，拒绝上述使用将导致您无法完成身份认证，但不会影响24小时买卖网平台其他功能的正常使用。这些信息仅供实名认证及法律法规所规定的用途，未经您的明示授权，不会用作其他目的。
      </p>
      <p>
        5.2为与您使用的终端机型适配消息推送功能，终端设备制造商（华为、小米、OPPO、VIVO等）可能通过SDK等技术使用您的手机型号、版本及相关设备信息。
      </p>
      <p>
        5.3客户服务：为及时处理您的投诉、建议以及其他诉求，客户服务提供商需要使用您的帐号及所涉及事件的相关信息，以及时了解、处理和响应相关问题。
      </p>
      <ol start="6">
        <li>实现安全与分析统计的数据使用</li>
      </ol>
      <p>
        6.1保障使用安全：我们非常重视帐号、服务及内容安全，为保障您和其他用户的帐号与财产安全，使您和我们的正当合法权益免受不法侵害，我们的合作方可能会使用必要的设备、帐号及日志信息。
      </p>
      <p>
        6.2分析产品情况：为分析我们产品的使用和表现情况，我们的合作方可能需要使用该产品使用情况（崩溃、闪退）、设备标识信息、应用总体安装使用情况等信息。
      </p>
      <p>
        6.3为提升相关领域的科研能力，促进科技发展水平，我们在确保数据安全与目的正当的前提下，可能会与合作的科研院所、高校等机构使用去标识化或匿名化的数据。
      </p>
      <p>7.帮助您参加营销推广活动</p>
      <p>
        当您选择参加我们及我们的合作方举办的有关营销活动时，为保障您在联合活动中获得体验一致的服务，或委托合作方及时向您兑现奖励，我们与我们的合作方可能需要使用您的姓名、通信地址、联系方式、银行账号等信息。拒绝上述使用可能会影响您参加相关活动，但不会影响其他功能。
      </p>
      <p>（二）转移</p>
      <p>
        随着我们业务的持续发展，我们将有可能进行合并、收购、资产转让，您的个人信息有可能因此而被转移。在发生前述变更时，我们将按照法律法规及不低于本隐私政策所要求的安全标准继续保护或要求个人信息的继受方继续保护您的个人信息，否则我们将要求继受方重新征得您的授权同意。
      </p>
      <p>（三）公开</p>
      <p>
        我们不会公开披露您的信息，除非遵循国家法律法规规定或者获得您的同意。我们公开披露您的个人信息会采用符合行业内标准的安全保护措施。对违规账号、欺诈行为进行处罚公告时，我们会披露相关账号的必要信息。
      </p>
      <p>（四）依法豁免征得同意共享、转让、公开披露的个人信息</p>
      <p>
        请您理解，在下列情形中，根据法律法规及国家标准，我们共享、转让、公开披露您的个人信息无需征得您的授权同意：
      </p>
      <p>
        （1）为订立、履行您作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；
      </p>
      <p>（2）为履行法定职责或者法定义务所必需；</p>
      <p>（3）与国家安全、国防安全直接相关的；</p>
      <p>（4）与刑事侦查、起诉、审判和判决执行等直接相关的</p>
      <p>
        （5）为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；
      </p>
      <p>
        （6）为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
      </p>
      <p>（7）依照相关法律规定处理您自行公开或者其他已经合法公开的个人信息；</p>
      <p>
        （8）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
      </p>
      <p>（9）法律、行政法规规定的其他情形。</p>
      <p>五、我们如何保护个人信息的安全</p>
      <p>
        （一）我们非常重视您个人信息的安全，将努力采取合理的安全措施（包括技术方面和管理方面）来保护您的个人信息，防止您提供的个人信息被不当使用或未经授权的情况下被访问、公开披露、使用、修改、损坏、丢失或泄漏。
      </p>
      <p>
        （二）我们会使用不低于行业同行的加密技术、匿名化处理等合理可行的手段保护您的个人信息，并使用安全保护机制防止您的个人信息遭到恶意攻击。
      </p>
      <p>
        （三）我们采取严格的数据使用和访问制度，确保只有授权人员才可访问您的个人信息，并适时对数据和技术进行安全审计。
      </p>
      <p>
        （四）尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保您提供给我们的个人信息的安全性。
      </p>
      <p>
        （五）您知悉并理解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的账号密码等个人信息透露给他人。
      </p>
      <p>
        （六）我们会制定应急处理预案，并在发生用户信息安全事件时立即启动应急预案，努力阻止该等安全事件的影响和后果扩大。一旦发生用户信息安全事件（泄露、丢失等）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已经采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以推送通知、邮件、信函、短信等形式告知您，难以逐一告知时，我们会采取合理、有效的方式发布公告。同时，我们还将按照相关监管部门要求，上报用户信息安全事件的处置情况。
      </p>
      <p>
        （七）我们谨此特别提醒您，本隐私政策提供的个人信息保护措施仅适用于&ldquo;24小时买卖网平台&rdquo;及相关服务。一旦您离开&ldquo;24小时买卖网平台&rdquo;及相关服务，浏览或使用其他网站、服务及内容资源，我们即没有能力及义务保护您在&ldquo;24小时买卖网平台&rdquo;及相关服务之外的软件、网站提交的任何个人信息，无论您登录、浏览或使用上述软件、网站是否基于&ldquo;24小时买卖网平台&rdquo;的链接或引导。
      </p>
      <p>六、管理您的个人信息</p>
      <p>
        我们非常重视您对个人信息的管理，并尽全力保护您对于您个人信息的查询、复制、更正、补充、删除、注销账号、以及设置隐私功能等权利，以使您有能力保障您的隐私和安全。
      </p>
      <p>
        您知悉并理解，注销账号的行为是不可逆的行为，当您注销账号后，我们将删除有关您的相关信息或进行匿名化处理，但法律法规另有规定的除外。
      </p>
      <p>七、未成年人使用条款</p>
      <p>
        24小时买卖网平台产品及服务面向成年人，如果您认为我们在无意间收集了未获得许可的未成年人信息，请通过在线客服联系我们以便我们可以尽快删除这些信息。
      </p>
      <p>八、隐私政策的修订和通知</p>
      <p>
        为给您提供更好的服务，&ldquo;24小时买卖网平台&rdquo;及相关服务将不时更新与变化，我们会适时对本隐私政策进行修订，这些修订构成本隐私政策的一部分并具有等同于本隐私政策的效力，未经您明确同意，我们不会削减您依据当前生效的本隐私政策所应享受的权利。
      </p>
      <p>&nbsp;</p>
    </div>
    <div v-else>
      <p>24小时买卖网平台用户协议</p>
      <p>&nbsp;</p>
      <p>一、服务条款的确认与接受</p>
      <p>
        1.&ldquo;24小时买卖网平台&rdquo;(包括移动客户端软件、应用程序及微信小程序)各项服务的所有权、运营权与最终解释权均归属于二十四小时买卖网（大连）科技有限公司(以下或简称&ldquo;24小时买卖网平台&rdquo;)所有。
      </p>
      <p>&nbsp;</p>
      <p>
        2.网站同意按照本协议之规定及其不定时发布的操作规则提供基于互联网和移动互联网的相关服务(以下或简称&ldquo;网络服务&rdquo;)，并按照所发布的相关服务条款和操作规则严格执行。
      </p>
      <p>&nbsp;</p>
      <p>
        3.为获得相关服务，您应当认真阅读、充分理解本《协议》中各条款,
        包括免除或者限制本网站责任的免责条款及对用户的权利限制条款，审慎阅读并选择接受或不接受本《协议》。同意接受本协议的全部条款的,
        您应当按照页面上的提示完成全部的注册程序,
        并在注册程序过程中点击&ldquo;同意&rdquo;,
        否则视为不接受本《协议》全部条款,应当终止并退出申请。
      </p>
      <p>&nbsp;</p>
      <p>
        4.注册为本网站用户并使用本网站，您应当具备相应的权利能力与行为能力（18周岁以下自然人需要在合法监护人的监护下方能注册并使用本网站）。
      </p>
      <p>&nbsp;</p>
      <p>
        5.根据国家有关法律、法规之规定以及本网站运营需要，本《协议》可由&ldquo;24小时买卖网平台&rdquo;定期更新，更新后的协议条款一旦公布即取代此前的协议条款,
        恕不另行通知，您可在本网站查阅最新版协议条款。在《协议》条款更新修改后,
        如您不接受修改后的条款，请立即停止使用&ldquo;24小时买卖网平台&rdquo;提供的服务,
        继续使用的将被视为已经接受了修改后的协议。
      </p>
      <p>&nbsp;</p>
      <p>6.基于本网站所提供网络服务的重要性，您确认并同意:</p>
      <p>&nbsp;</p>
      <p>
        6.1提供的注册资料真实、准确、完整、合法有效，注册资料如有变动的，应当及时更新；
      </p>
      <p>&nbsp;</p>
      <p>
        6.2如您所提供的注册信息、资料等不合法、不真实、不准确、不详尽的，您需承担由此引起的相应责任及后果，并且&ldquo;24小时买卖网平台&rdquo;保留终止您使用本网站各项服务的权利。
      </p>
      <p>&nbsp;</p>
      <p>二、服务内容</p>
      <p>
        网络服务的具体内容由&ldquo;24小时买卖网平台&rdquo;实际提供,
        包括通过商城参加的秒杀活动、订单管理、直播、在线权益转让等相关内容。
      </p>
      <p>&nbsp;</p>
      <p>三、用户账号</p>
      <p>1.您经注册系统完成注册程序并通过身份认证即为正式用户。</p>
      <p>&nbsp;</p>
      <p>
        2.如发现您的账号中含有不雅文字或不恰当名称的，&ldquo;24小时买卖网平台&rdquo;保留注销您的用户账号的权利。
      </p>
      <p>&nbsp;</p>
      <p>
        3.您始终有义务保证账号的安全，利用账号所进行的一切活动引起的任何损失或损害，由您自行承担全部责任，&ldquo;24小时买卖网平台&rdquo;不承担任何责任。如您发现账号遭到未授权的使用或发生其他任何安全问题,
        应立即修改账号密码并妥善保管。因黑客行为或您的保管疏忽导致账号非法使用,
        &ldquo;24小时买卖网平台&rdquo;不承担任何责任。
      </p>
      <p>&nbsp;</p>
      <p>
        4.用户账号和密码仅由初始申请注册人使用，作为初始申请注册人，您不得将自己用户账户或密码有偿或无偿以转让、出借、授权等方式提供给第三人操作和使用,
        否则您应当自行承担因违反此要求而遭致的任何损失。违反本项约定的，&ldquo;24小时买卖网平台&rdquo;保留收回账号的权利。若因违反本约定对他人造成损害的，您应与实际使用人承担连带赔偿责任，同时&ldquo;24小时买卖网平台&rdquo;保留追究用户违约责任的权利。
      </p>
      <p>&nbsp;</p>
      <p>
        5.您的账号在丢失、遗忘密码及因合用产生使用权归属纠纷后,
        须遵照&ldquo;24小时买卖网平台&rdquo;的申诉途径请求找回账号。您可以凭初始注册资料向&ldquo;24小时买卖网平台&rdquo;申请找回账号。&ldquo;24小时买卖网平台&rdquo;的账户恢复机制仅负责识别申请用户所提资料与系统记录资料是否一致,
        而无法识别申诉人是否系账号的真正使用权人。对用户因被他人冒名申请而致的任何损失，&ldquo;24小时买卖网平台&rdquo;不承担任何责任,
        您应当知晓账号及密码保管责任在于用户自身，&ldquo;24小时买卖网平台&rdquo;并不承诺账号丢失或遗忘密码后用户一定能通过申诉找回账号。用户应当谨慎填写初始手机号或注册邮箱作为确认接收争议账号的指定邮箱。
      </p>
      <p>&nbsp;</p>
      <p>
        6.&ldquo;24小时买卖网平台&rdquo;建议您应当使用本人名义为用户账户充值或行使付款行为。若您存在使用第三人的名义进行充值或付款，或委托第三人代为充值或付款之行为的，则以上行为被视作您本人的行为，若由于该第三人行为导致充值或付款行为失败或成功后又被撤销的，均会被认为是您本人真实意思的表示和用户本人实施的行为，由此所造成的一切法律后果均由您户自行承担。
      </p>
      <p>&nbsp;</p>
      <p>四、用户行为规范</p>
      <p>
        1.您在使用&ldquo;24小时买卖网平台&rdquo;网络服务过程中,
        必须遵循国家的相关法律、法规之规定,
        不得发布危害国家安全、色情、暴力、侵犯知识产权等任何合法权利等非法内容;
        也不得发布含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、或其它道德上令人反感的内容。
      </p>
      <p>&nbsp;</p>
      <p>
        2.&ldquo;24小时买卖网平台&rdquo;可依其合理判断,
        对违反有关法律法规或本协议约定;
        或侵犯、妨害、威胁任何人权利或安全的内容或者假冒他人的行为，&ldquo;24小时买卖网平台&rdquo;有权停止传输任何前述内容，并有权依其自行判断对违反本条款的任何用户采取适当的法律行动，包括但不限于：删除具有违法性、侵权性、不当性等内容，阻止其使用&ldquo;24小时买卖网平台&rdquo;全部或部分网络服务，并且依据法律法规保存有关信息并向有关行政、司法机关报告等。
      </p>
      <p>&nbsp;</p>
      <p>
        3.对于经由&ldquo;24小时买卖网平台&rdquo;网络服务而传送的内容,
        &ldquo;24小时买卖网平台&rdquo;不保证前述内容的正确性、完整性或品质。您在接受本服务时，有可能会接触到令人不快、不适当或令人厌恶的内容。在任何情况下，&ldquo;24小时买卖网平台&rdquo;均不对任何内容负责，包括但不限于任何内容发生任何错误或纰漏以及衍生的任何损失或损害。&ldquo;24小时买卖网平台&rdquo;有权(但无义务)自行拒绝或删除经由&ldquo;24小时买卖网平台&rdquo;网络服务提供的任何内容。您使用上述内容,
        应自行承担风险。
      </p>
      <p>&nbsp;</p>
      <p>五、所有权及知识产权声明</p>
      <p>
        1.您一旦接受本协议，对于您提供、上传、创作、发布在本网站的文字、评论、图片、照片、视频、音频等任何形式的信息内容（包括但不限于客户评价、客户咨询、各类话题文章等），您免费授予&ldquo;24小时买卖网平台&rdquo;及其关联公司至保护期终止为止、不可撤销的、全球范围的、排他的许可使用著作权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可许可使用的权利）、肖像权、用户ID名称等相关的全部合法权利权益，就&ldquo;24小时买卖网平台&rdquo;具有诉讼主体资格的权利、权益，&ldquo;24小时买卖网平台&rdquo;有权以自身名义对第三方侵权行为取证及提起诉讼。您同意&ldquo;24小时买卖网平台&rdquo;及其关联公司有权存储、使用、复制、修订、编辑、发布、展示、翻译、分发、推广、出版、发行、信息网络传播您以上信息内容，有权改编制作派生作品，并以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内。
      </p>
      <p>&nbsp;</p>
      <p>
        2.&ldquo;24小时买卖网平台&rdquo;有权再许可给其他第三方上述第1款的所有权利。
      </p>
      <p>&nbsp;</p>
      <p>
        3.您应确保在&ldquo;24小时买卖网平台&rdquo;网站上发表的本条第1款各类信息内容均不涉及侵犯第三方肖像、隐私、知识产权或其他合法权益。否则&ldquo;24小时买卖网平台&rdquo;有权随时采取包括但不限于删除、断开链接等措施。
      </p>
      <p>&nbsp;</p>
      <p>
        4.本协议已经构成《中华人民共和国著作权法》及相关法律规定的著作财产权等权利许可使用的书面协议，其效力及于您在本网站上发布的任何受著作权法保护的作品内容，无论该等内容形成于本协议订立前还是本协议订立后。
      </p>
      <p>&nbsp;</p>
      <p>
        5.您同意并已充分了解本协议的条款，承诺不将已发表于本网站的信息，以任何形式发布或授权其它主体以任何方式使用（包括但不限于在各类网站、媒体上使用）。
      </p>
      <p>&nbsp;</p>
      <p>
        6.除法律另有强制性规定外，未经&ldquo;24小时买卖网平台&rdquo;明确的特别书面同意,任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用本网站的信息内容，否则，&ldquo;24小时买卖网平台&rdquo;有权追究其法律责任。
      </p>
      <p>&nbsp;</p>
      <p>
        7.本网站所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是&ldquo;24小时买卖网平台&rdquo;或其内容提供者的财产，受中国和国际版权法的保护。本网站上所有内容的汇编是&ldquo;24小时买卖网平台&rdquo;的排他财产，受中国和国际版权法的保护。所有软件都是&ldquo;24小时买卖网平台&rdquo;或其关联公司或其供应商的财产，受中国和国际版权法的保护。
      </p>
      <p>&nbsp;</p>
      <p>六、用户个人信息保护及授权</p>
      <p>
        1.您知悉并同意，为方便您使用本网站相关服务，本网站将存储您在使用时的必要信息，包括但不限于您的真实姓名、性别、生日、地址、联系方式、通讯录、相册、日历、定位信息等。除法律法规规定的情形外，未经您的许可&ldquo;24小时买卖网平台&rdquo;不会向第三方公开、透露您的个人信息。&ldquo;24小时买卖网平台&rdquo;对相关信息采取专业加密存储与传输方式，利用合理措施保障用户个人信息的安全。
      </p>
      <p>&nbsp;</p>
      <p>
        2.您知悉并确认，您在注册账号或使用本网站的过程中，需要提供真实的身份信息，&ldquo;24小时买卖网平台&rdquo;将根据国家法律法规相关要求，进行真实身份信息认证。若您提供的信息不真实、不完整，则无法使用本网站或在使用过程中受到限制，同时，由此产生的不利后果，由您自行承担。
      </p>
      <p>&nbsp;</p>
      <p>
        3.您在使用本网站某一特定服务时，该服务可能会另有单独的协议、相关业务规则等，您在使用该项服务前请阅读并同意相关的单独协议；您使用前述特定服务，即视为您已阅读并同意接受相关单独协议。
      </p>
      <p>&nbsp;</p>
      <p>4.您充分理解并同意:</p>
      <p>&nbsp;</p>
      <p>4.1接收通过邮件、短信、电话等形式的有关订单的信息、有关活动的信息；</p>
      <p>&nbsp;</p>
      <p>
        4.2为配合行政监管机关、司法机关工作，在法律规定范围内&ldquo;24小时买卖网平台&rdquo;有权向上述行政、司法机关提供您在使用本网站时所储存的相关信息，包括但不限于您的注册信息等，或使用相关信息进行证据保全，包括但不限于公证、见证等。
      </p>
      <p>&nbsp;</p>
      <p>七、快捷兑换</p>
      <p>
        &ldquo;24小时买卖网平台快捷兑换服务&rdquo;(以下或简称&ldquo;本服务&rdquo;)由&ldquo;24小时买卖网平台&rdquo;(包括移动客户端软件、应用程序及微信小程序)，依据本线上协议的相关约定向您提供。为了保障您的权益，请您确认在使用本服务之前，已详细阅读本协议的所有内容，一旦您勾选同意《快捷兑换协议》，即表示您本人已阅读、充分理解并同意本协议的所有内容；同时您同意依照本协议的约定，授权所有需要您授权的内容。本协议将构成您与本公司就&ldquo;24小时买卖网平台快捷兑换&rdquo;服务所订立的有效合约。
      </p>
      <p>&nbsp;</p>
      <p>
        &ldquo;24小时买卖网平台快捷兑换&rdquo;，指您无需验证您的兑换密码，即可从您的账户中扣划交易款项至商户账户。商户，在本协议中特指您所购买商品或服务的交易相对方或交易相对方所在平台/网站。
      </p>
      <p>&nbsp;</p>
      <p>
        您在此同意并确认，您使用本服务的意思表示出自您的真实意愿；同时您对使用本服务过程中发出的指令的真实性及有效性承担全部责任，该指令视为您的真实意思表示且不可变更或撤销。您承诺，&ldquo;24小时买卖网平台快捷兑换服务&rdquo;依照您的指令进行操作的一切风险均由您承担。
      </p>
      <p>&nbsp;</p>
      <p>
        甚于风险控制的需要，本公司对您使用本服务进行兑换时的场景、资金渠道、兑换额度等进行限制，您均予以理解和接受，具体额度以页面提示为准。本公司有权根据客观情况和风险控制需要随时降低您的交易额度和次数，您均予以确认和接受。但本公司不会未经您的同意提高您自行设置交易额度和次数。
      </p>
      <p>&nbsp;</p>
      <p>
        为满足交易的便捷性，您同意，即使您在签订本协议时并未开通本服务，后续您在向商城开通快捷兑换等功能的，本公司有权将快捷兑换作为您的扣款渠道。
      </p>
      <p>&nbsp;</p>
      <p>
        您在此同意并确认，&ldquo;24小时买卖网平台快捷兑换&rdquo;服务是我公司为您提供的增值服务，商城是被授权指令的执行方，对快捷兑换指令的真实性和准确性不负有审查义务，除非我公司未按照您本人的授权及您本人指定的商户的指令操作或执行指令错误，我公司将不对任何因本协议之履行而导致的您的任何直接损失和间接损失承担法律责任。您如果对使用&ldquo;24小时买卖网平台快捷兑换服务&rdquo;的具体业务类型及交易金额有异议，或因您购买商品或服务而与商家产生的任何纠纷，请自行与您指定的商户协商处理或与其通过司法途径解决。
      </p>
      <p>&nbsp;</p>
      <p>
        您在此同意并确认，在开通并使用本24小时买卖网平台快捷兑换服务的过程中，您应准确、真实、及时和完整提供或更新我公司要求的您的相关信息，否则因上述信息不准确、不真实、不及时和不完整而产生的一切经济纠纷或违约责任等全部由您本人承担。
      </p>
      <p>&nbsp;</p>
      <p>
        您在此同意并确认，我公司有权随时修改、修订或解释本协议的有关条款，一旦上述内容发生变动，我公司将会通过网站公告方式告知您。如果您不同意我公司对上述内容所做的修改、修订或解释，您应立即停止使用本协议约定的&ldquo;24小时买卖网平台快捷兑换服务&rdquo;。一旦您继续使用&ldquo;24小时买卖网平台快捷兑换服务&rdquo;服务的，视为您理解并接受我公司对上述内容的修改、修订或解释。
      </p>
      <p>&nbsp;</p>
      <p>
        您在此同意并确认，不会利用本服务从事任何非法的或违反本协议目的或者侵犯其他第三方权益的行为，否则商城有权立即单方面终止提供本服务，并不承担任何责任；如果因您的行为给本公司造成损失的，您应承担全部赔偿责任。
      </p>
      <p>&nbsp;</p>
      <p>
        本协议自您勾选同意《快捷兑换协议》之日起生效，您依据本协议的约定而进行的全部授权亦同时生效；您的全部授权在我公司收到您本人递交的解除本协议的申请时终止；在终止之前我公司依照您本人的授权进行操作的一切风险均由您本人自行承担。
      </p>
      <p>&nbsp;</p>
      <p>
        本协议将作为《24小时买卖网平台用户协议》的关联协议，并共同组成您与本公司达成的整体合同约定，本协议没有约定的内容，适用《24小时买卖网平台用户协议》及其他关联协议。
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>八、违约责任</p>
      <p>
        1.如&ldquo;24小时买卖网平台&rdquo;发现或收到他人举报投诉您违反本协议约定或存在任何恶意行为的，我们有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规账号处以包括但不限于警告、限制或禁止使用部分或全部功能、账号封禁、注销等处罚，并公告处理结果。
      </p>
      <p>&nbsp;</p>
      <p>
        2.&ldquo;24小时买卖网平台&rdquo;有权依据合理判断对违反有关法律法规或本协议规定的行为采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，您应独自承担由此而产生的一切法律责任。
      </p>
      <p>&nbsp;</p>
      <p>
        3.您理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任，&ldquo;24小时买卖网平台&rdquo;因此遭受损失或因承担连带赔偿责任而先行支付赔偿款项的的，您也应当一并赔偿。
      </p>
      <p>&nbsp;</p>
      <p>
        4.除非另有明确的书面说明,
        &ldquo;24小时买卖网平台&rdquo;不对本网站的运营及其包含在本网站上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的除外）。
      </p>
      <p>&nbsp;</p>
      <p>九、管辖及其他</p>
      <p>
        1.本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。如双方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，应向&ldquo;24小时买卖网平台&rdquo;运营所在地有管辖权的人民法院提起诉讼。
      </p>
      <p>&nbsp;</p>
      <p>
        2.如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。
      </p>
      <p>&nbsp;</p>
      <p>
        3.本协议未明示授权的其他权利仍由&ldquo;24小时买卖网平台&rdquo;保留，您在行使这些权利时须另外取得&ldquo;24小时买卖网平台&rdquo;的书面许可。&ldquo;24小时买卖网平台&rdquo;如果未行使前述任何权利，并不构成对该权利的放弃。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AgreementView",
  data() {
    return {
      user: false,
    };
  },
  created() {
    this.user = this.$route.query.user;
    console.log(this.$route.query, this.user);
  },
};
</script>

<style scoped></style>
